import { Paper, IconButton } from "@mui/material";
import { props } from "lodash/fp";
import { Dispatch, SetStateAction } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NavigationIcon from "@mui/icons-material/Navigation";

interface ZoomControlProps {
  dynamicZoom: number;
  onZoom: (zoom: number) => void;
  resetCompass: () => void;
}

export const ZoomControl = (props: ZoomControlProps) => {
  return (
    <Paper
      sx={{
        width: "40px",
        height: "120px",
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-end",
      }}
    >
      <IconButton onClick={() => props.resetCompass()}>
        <NavigationIcon />
      </IconButton>
      <IconButton onClick={() => props.onZoom(props.dynamicZoom + 0.5)}>
        <AddIcon />
      </IconButton>
      <IconButton onClick={() => props.onZoom(props.dynamicZoom - 0.5)}>
        <RemoveIcon />
      </IconButton>
    </Paper>
  );
};
