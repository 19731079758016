import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider, Link, TextField, Typography
} from "@mui/material";
import { Auth } from "aws-amplify";
import { BaseState } from "common/models";
import { Form, Formik, FormikConfig } from "formik";
import { useState } from "react";
import * as Routes from "routes";
import { v4 } from "uuid";
import { InferType, object, string } from "yup";

import { useMessageState } from "./use-message-state";

export const ResendVerificationValidationSchema = object({
  email: string().email("Enter a valid email").required("Email is required"),
});

interface ResendVerificationValues {
  email: string;
}

interface ResendVerificationState extends BaseState {}

export const ResendVerification = () => {
  const [state, setState] = useState<ResendVerificationState>({
    isError: false,
    message: "",
    isLoading: false,
  });

  const [message, setMessage] = useMessageState({ type: null, text: "" });

  const setLoading = (isLoading: boolean) => {
    setState((currentState) => ({ ...currentState, isLoading }));
  };

  const onSubmit: FormikConfig<
    InferType<typeof ResendVerificationValidationSchema>
  >["onSubmit"] = async ({ email }) => {
    try {
      setLoading(true);
      await Auth.resendSignUp(email);
      setMessage({
        type: "success",
        text: `Verification link has been successfully resent to ${email}.`,
      });
    } catch (e: any) {
      if (e?.message.includes("resend_confirmation_failure")) {
        setMessage({
          type: "error",
          text: `Verification link could not be sent to ${email}. Please try again and contact support if the issue persists.`,
        });
      } else {
        setMessage({
          type: "error",
          text: `An error occurred in sending the verification link to ${email}. Please try again and contact support if the issue persists.`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik<ResendVerificationValues>
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
        validationSchema={ResendVerificationValidationSchema}>
        {({ values: { email }, handleChange, handleBlur, errors, touched }) => (
          <Form>
            <Card style={{ padding: "16px" , paddingBottom: '0px'}}>
              <Typography variant="h5" color="text.primary">
                Resend Verification
              </Typography>
              <Typography variant="body2" color="text.secondary">
                If you have lost your verification link, we can send another.
              </Typography>

              <TextField
                id="email"
                name="email"
                label="Email"
                type="text"
                margin="normal"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                disabled={state.isLoading}
                // variant="filled"
                fullWidth
              />
              <Box marginY={2}>
                {state.isLoading ? (
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {message.text === "" || !message.type ? null : (
                      <Box marginBottom={2} marginTop={2}>
                        <Alert variant="outlined" severity={message.type}>
                          {message.text}
                        </Alert>
                      </Box>
                    )}
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "16px",
                      }}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={state.isLoading}
                        fullWidth
                        children="Resend"
                      />

                      <Divider />

                      <Button
                        LinkComponent={Link}
                        disabled={state.isLoading}
                        size="small"
                        fullWidth
                        href={Routes.signIn}>
                        Sign In
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
      <Divider />
      <Box>{/* <Link href="sign-in">Sign in</Link> */}</Box>
    </>
  );
};
