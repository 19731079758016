import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchAdminUserMemberships } from "data/queries";
import { CsvBuilder } from "filefy";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { Cancel, FileDownload } from "@mui/icons-material";
import { Box, Button, Card, IconButton, Skeleton } from "@mui/material";

import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import ConfirmationModal from "components/modals/confirmation-modal";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { NameEmailAvatarRound } from "components/shared/shared";
import { UserMembership } from "data/models";
import { deleteAdminUserMembership } from "data/mutations";
import { useState } from "react";

interface HistoryProps {
  apigeeDeveloperId: string;
  accountName: string;
}

const AdminUserMemberships = () => {
  const history = useHistory();

  const [isDeleteMemberOpen, setIsDeleteMemberOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<UserMembership>({
    organisationApigeeDeveloperId: "",
    apigeeDeveloperId: "",
    status: "",
    organisationName: "",
    organisationEmail: "",
    developerName: "",
    developerEmail: "",
  });

  const apigeeDeveloperId = (history.location.state as HistoryProps)
    .apigeeDeveloperId;
  const accountName = (history.location.state as HistoryProps).accountName;

  const [identityState] = useIdentity();
  const user = GetUserIfLoggedIn(identityState);

  const adminUserMembershipsQuery = useQuery(
    [QueryKeys.adminUserMemberships, apigeeDeveloperId],
    () => fetchAdminUserMemberships(apigeeDeveloperId, identityState)
  );

  const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const adminDeleteUserMembershipMutation = useMutation(
    () =>
      deleteAdminUserMembership(
        memberToDelete.organisationApigeeDeveloperId,
        apigeeDeveloperId,
        identityState
      ),
    {
      onSuccess: (deletedOrganisationMember: any) => {
        queryClient.setQueryData(
          [QueryKeys.adminUserMemberships, apigeeDeveloperId],
          adminUserMembershipsQuery.data?.filter(
            (userInvitation: UserMembership) =>
              userInvitation.organisationApigeeDeveloperId !==
              deletedOrganisationMember.orgId
          )
        );
        setIsDeleteMemberOpen(false);
      },
    }
  );

  const isSuccess = adminUserMembershipsQuery.isSuccess;
  const isLoading = adminUserMembershipsQuery.isLoading;

  const downloadCSV = () => {
    if (isSuccess) {
      const builder = new CsvBuilder(
        `${accountName}-organisation-membership-list.csv`
      );
      builder
        .setDelimeter(",")
        .setColumns(["organiationName", "role", "status"])
        .addRows(
          adminUserMembershipsQuery.data.map((member: UserMembership) => [
            member.organisationName,
            "Member",
            member.status,
          ])
        )
        .exportFile();
    }
  };

  const columns: GridColDef[] = [
    {
      flex: 2,
      field: "orgName",
      headerName: "Organisation Name",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <Box marginTop={2}>
          <NameEmailAvatarRound
            name={params.value}
            variant={"circular"}
            isLoading={false}
            email={params.row.orgEmail}
          />
        </Box>
      ),
    },
    {
      flex: 1,
      field: "role",
      headerName: "Role",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          startIcon={<Cancel />}
          variant="outlined"
          color="primary"
          size="medium"
          disabled={!user || user.email === params.row.email}
          style={{ marginLeft: 16 }}
          onClick={() => {
            setMemberToDelete({
              organisationApigeeDeveloperId: params.row.id,
              apigeeDeveloperId: params.row.id,
              status: params.row.status,
              organisationName: params.row.orgName,
              organisationEmail: params.row.orgEmail,
              developerName: params.row.name,
              developerEmail: params.row.email,
            });
            setIsDeleteMemberOpen(true);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        top: "-70px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          marginBottom: "50px",
        }}
      >
        {isLoading && (
          <Skeleton variant="rectangular" width={181} height={42} />
        )}
      </Box>

      <ReactQueryErrorWrapper
        queries={[adminUserMembershipsQuery]}
        mutations={[adminDeleteUserMembershipMutation]}
      />

      {isLoading && (
        <Box>
          <Skeleton variant="rectangular" width={1200} height={450} />
        </Box>
      )}

      {isSuccess && (
        <>
          <Card>
            <DataGridPremium
              autoHeight={true}
              disableRowSelectionOnClick={true}
              hideFooterSelectedRowCount={true}
              rowHeight={72}
              rows={adminUserMembershipsQuery.data.map((orgMember: any) => {
                return {
                  id: orgMember.organisationApigeeDeveloperId,
                  devId: orgMember.apigeeDeveloperId,
                  orgName: orgMember.organisationName,
                  orgEmail: orgMember.organisationEmail,
                  email: orgMember.developerEmail,
                  name: orgMember.developerName,
                  role: "Member",
                  status: orgMember.status,
                };
              })}
              columns={columns}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5]}
              checkboxSelection={false}
            />
          </Card>
          <IconButton
            onClick={() => downloadCSV()}
            sx={{
              position: "relative",
              top: "-47px",
              marginLeft: "6px",
            }}
          >
            <FileDownload />
          </IconButton>
          <ConfirmationModal
            isLoading={adminDeleteUserMembershipMutation.isLoading}
            isError={adminDeleteUserMembershipMutation.isError}
            open={isDeleteMemberOpen}
            header={"Confirm Member Removal"}
            body={`Are you sure you want to remove "${
              memberToDelete.developerName
                ? memberToDelete.developerName
                : memberToDelete.developerEmail
            }" from "${
              memberToDelete.organisationName
            }", by doing this they will no longer be able to access this org unless invited again.`}
            leftButtonText={"Remove member"}
            leftButtonColor="primary"
            rightButtonText={"Cancel"}
            rightButtonColor="warning"
            handleClose={() => {
              adminDeleteUserMembershipMutation.reset();
              setIsDeleteMemberOpen(false);
            }}
            handleLeftButton={() => adminDeleteUserMembershipMutation.mutate()}
            handleRightButton={() => {
              adminDeleteUserMembershipMutation.reset();
              setIsDeleteMemberOpen(false);
            }}
          ></ConfirmationModal>
        </>
      )}
    </Box>
  );
};

export { AdminUserMemberships };
