import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchAdminOrgMembers } from "data/queries";
import { CsvBuilder } from "filefy";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { Cancel, FileDownload, Mail } from "@mui/icons-material";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";

import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import ConfirmationModal from "components/modals/confirmation-modal";
import { MemberInvitationModal } from "components/settings/organization/members/invite-members";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { NameEmailAvatarRound } from "components/shared/shared";
import { UserMembership } from "data/models";
import {
  adminAddOrganisationMember,
  adminDeleteOrganisationMember,
} from "data/mutations";
import { useEffect, useState } from "react";

interface HistoryProps {
  orgApigeeDeveloperId: string;
  accountName: string;
}

interface AlertInviteState {
  message: string;
  severity: AlertColor;
}
let alertStateList: AlertInviteState[] = [];

const AdminMembers = () => {
  const history = useHistory();

  const [isDeleteMemberOpen, setIsDeleteMemberOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<UserMembership>({
    organisationApigeeDeveloperId: "",
    apigeeDeveloperId: "",
    status: "",
    organisationName: "",
    organisationEmail: "",
    developerName: "",
    developerEmail: "",
  });

  useEffect(() => {
    if (alertStateList.length !== 0) {
      const timeId = setTimeout(() => {
        alertStateList = [];
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [alertStateList.length]);

  const [isMemberInvitationModalOpen, setIsMemberInvitationModalOpen] =
    useState(false);

  const orgApigeeDeveloperId = (history.location.state as HistoryProps)
    .orgApigeeDeveloperId;
  const accountName = (history.location.state as HistoryProps).accountName;

  const [identityState] = useIdentity();
  const user = GetUserIfLoggedIn(identityState);

  const adminOrganisationMembersQuery = useQuery(
    [QueryKeys.adminOrganisationMembers, orgApigeeDeveloperId],
    () => fetchAdminOrgMembers(orgApigeeDeveloperId, identityState)
  );

  const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const adminDeleteOrganisationMemberMutation = useMutation(
    () =>
      adminDeleteOrganisationMember(
        orgApigeeDeveloperId,
        memberToDelete.apigeeDeveloperId,
        identityState
      ),
    {
      onSuccess: (deletedOrganisationMember: any) => {
        queryClient.setQueryData(
          [QueryKeys.adminOrganisationMembers, orgApigeeDeveloperId],
          adminOrganisationMembersQuery.data.filter(
            (userInvitation: UserMembership) =>
              userInvitation.apigeeDeveloperId !==
              deletedOrganisationMember.devId
          )
        );
        setIsDeleteMemberOpen(false);
      },
    }
  );

  const adminAddOrganisationMemberMutation = useMutation(
    (emails: any) => {
      return adminAddOrganisationMember(
        orgApigeeDeveloperId,
        emails,
        identityState
      );
    },
    {
      onSuccess: (invitedUsers: any[]) => {
        invitedUsers.forEach((invitedUser, index) => {
          if (invitedUser.messages) {
            alertStateList.push({
              message: invitedUser.messages.join(". "),
              severity: "error",
            });
            return;
          }

          const message = `Invitation to ${
            invitedUser.developerEmail
          } has been sent. ${
            invitedUser.userExisted
              ? "Invited user is already registered with Geoscape."
              : "Invited user does not exist in Geoscape. They may need to sign up."
          }`;

          alertStateList.push({
            message: message,
            severity: "info",
          });

          adminOrganisationMembersQuery.data.push(invitedUser);
          queryClient.setQueryData(
            [QueryKeys.adminOrganisationMembers, orgApigeeDeveloperId],
            adminOrganisationMembersQuery.data
          );
        });
        setIsMemberInvitationModalOpen(false);
      },
    }
  );

  const isSuccess = adminOrganisationMembersQuery.isSuccess;
  const isLoading = adminOrganisationMembersQuery.isLoading;

  const downloadCSV = () => {
    const builder = new CsvBuilder(`${accountName}-members-list.csv`);
    builder
      .setDelimeter(",")
      .setColumns(["developerName", "developerEmail", "role", "status"])
      .addRows(
        adminOrganisationMembersQuery.data.map((member: UserMembership) => [
          member.developerName,
          member.developerEmail,
          "Member",
          member.status,
        ])
      )
      .exportFile();
  };

  const columns: GridColDef[] = [
    {
      flex: 2,
      field: "name",
      headerName: "Members",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <Box marginTop={2}>
          <NameEmailAvatarRound
            name={params.value}
            variant={"circular"}
            isLoading={false}
            email={params.row.email}
          />
        </Box>
      ),
    },
    {
      flex: 1,
      field: "role",
      headerName: "Role",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "action",
      headerName: "Action",
      sortable: false,
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          startIcon={<Cancel />}
          variant="outlined"
          color="primary"
          size="medium"
          disabled={!user || user.email === params.row.email}
          style={{ marginLeft: 16 }}
          onClick={() => {
            setMemberToDelete({
              organisationApigeeDeveloperId: params.row.orgId,
              apigeeDeveloperId: params.row.id,
              status: params.row.status,
              organisationName: params.row.orgName,
              organisationEmail: params.row.orgEmail,
              developerName: params.row.name,
              developerEmail: params.row.email,
            });
            setIsDeleteMemberOpen(true);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        top: "-70px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          marginBottom: "50px",
        }}
      >
        {isLoading && (
          <Skeleton variant="rectangular" width={181} height={42} />
        )}

        {isSuccess && (
          <>
            <Button
              onClick={() => setIsMemberInvitationModalOpen(true)}
              startIcon={<Mail />}
              variant="contained"
            >
              Invite Members
            </Button>

            <MemberInvitationModal
              isOpen={isMemberInvitationModalOpen}
              isLoading={adminAddOrganisationMemberMutation.isLoading}
              isError={adminAddOrganisationMemberMutation.isError}
              handleClose={() => {
                adminAddOrganisationMemberMutation.reset();
                setIsMemberInvitationModalOpen(false);
              }}
              handleSubmit={(emailList: string[]) =>
                adminAddOrganisationMemberMutation.mutate(emailList)
              }
            />
          </>
        )}
      </Box>

      <ReactQueryErrorWrapper
        queries={[adminOrganisationMembersQuery]}
        mutations={[
          adminDeleteOrganisationMemberMutation,
          adminAddOrganisationMemberMutation,
        ]}
      />

      {alertStateList.length !== 0 && (
        <Stack spacing={2} sx={{ marginBottom: "16px" }}>
          {alertStateList
            .filter((m) => m.message)
            .map((alert: AlertInviteState, index) => (
              <Alert key={index} variant="filled" severity={alert.severity}>
                {alert.message}
              </Alert>
            ))}
        </Stack>
      )}

      {adminOrganisationMembersQuery.isLoading && (
        <Box>
          <Skeleton variant="rectangular" width={1200} height={450} />
        </Box>
      )}

      {adminOrganisationMembersQuery.isSuccess && (
        <>
          <Card>
            <DataGridPremium
              autoHeight={true}
              disableRowSelectionOnClick={true}
              hideFooterSelectedRowCount={true}
              rowHeight={72}
              rows={adminOrganisationMembersQuery.data.map((orgMember: any) => {
                return {
                  id: orgMember.apigeeDeveloperId,
                  orgId: orgMember.organisationApigeeDeveloperId,
                  orgName: orgMember.organisationName,
                  orgEmail: orgMember.organisationEmail,
                  members: orgMember.developerName,
                  email: orgMember.developerEmail,
                  name: orgMember.developerName,
                  role: "Member",
                  status: orgMember.status,
                };
              })}
              columns={columns}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5]}
              checkboxSelection={false}
            />
          </Card>
          <IconButton
            onClick={() => downloadCSV()}
            sx={{
              position: "relative",
              top: "-47px",
              marginLeft: "6px",
            }}
          >
            <FileDownload />
          </IconButton>
          <ConfirmationModal
            isLoading={adminDeleteOrganisationMemberMutation.isLoading}
            isError={adminDeleteOrganisationMemberMutation.isError}
            open={isDeleteMemberOpen}
            header={"Confirm Member Removal"}
            body={`Are you sure you want to remove "${
              memberToDelete.developerName
                ? memberToDelete.developerName
                : memberToDelete.developerEmail
            }" from "${
              memberToDelete.organisationName
            }", by doing this they will no longer be able to access this org unless invited again.`}
            leftButtonText={"Remove member"}
            leftButtonColor="primary"
            rightButtonText={"Cancel"}
            rightButtonColor="warning"
            handleClose={() => {
              adminDeleteOrganisationMemberMutation.reset();
              setIsDeleteMemberOpen(false);
            }}
            handleLeftButton={() =>
              adminDeleteOrganisationMemberMutation.mutate()
            }
            handleRightButton={() => {
              adminDeleteOrganisationMemberMutation.reset();
              setIsDeleteMemberOpen(false);
            }}
          ></ConfirmationModal>
        </>
      )}
    </Box>
  );
};

export { AdminMembers };
