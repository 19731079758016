import { Button, CssBaseline, IconButton } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Amplify from "aws-amplify";
import { ApplicationLoading } from "components/loading";
import { SignedIn } from "components/signed-in";
import { SignedOut } from "components/signed-out";
import { IdentityProvider, useIdentity } from "contexts/identity-context";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Redirect, useHistory } from "react-router";
import { IntercomProvider } from "react-use-intercom";
import { GeoscapeDeveloperThemeProvider } from "theme";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider, closeSnackbar } from "notistack";
import CancelIcon from "@mui/icons-material/Cancel";
import { CloseButton } from "components/snackbars/CloseButton";

import.meta.env.VITE_SENTRY_DSN &&
  import.meta.env.VITE_ENVIRONMENT &&
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
  });

// configure auth settings ...
Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: import.meta.env.VITE_DOMAIN,
      path: "/",
      expires: 365,
      sameSite: "lax",
      secure: true,
    },
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

const intercomAppId: string = import.meta.env.VITE_INTERCOM_APP_ID
  ? import.meta.env.VITE_INTERCOM_APP_ID
  : "";

const queryClient = new QueryClient();

// configure stripe settings ...
const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLIC_KEY
    ? import.meta.env.VITE_STRIPE_PUBLIC_KEY
    : ``
);

const UserStateSwitch = () => {
  /**
   * Conditionally returns components depending if we're signed in or not
   */

  const [userState] = useIdentity();

  switch (userState) {
    case false:
      return <SignedOut />;
    case "loading":
      return <ApplicationLoading />;
    default:
      return <SignedIn />;
  }
};

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <IntercomProvider appId={intercomAppId}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={6000}
            action={(snackbarId) => <CloseButton snackbarId={snackbarId} />}
          >
            <Router>
              <GeoscapeDeveloperThemeProvider>
                <CssBaseline />
                <Elements stripe={stripePromise}>
                  <CookiesProvider>
                    <IdentityProvider>
                      <UserStateSwitch />
                    </IdentityProvider>
                  </CookiesProvider>
                </Elements>
              </GeoscapeDeveloperThemeProvider>
            </Router>
          </SnackbarProvider>
        </IntercomProvider>
        <ReactQueryDevtools position="top-right" />
      </QueryClientProvider>
    </div>
  );
}

export default App;
