import { Box, Breadcrumbs, Typography } from "@mui/material";
import _ from "lodash";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Routes from "routes";

interface HistoryProps {
  accountId?: string;
  accountName?: string;
}

export const DynamicBreadcrumbsMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const currentRouteArray = location.pathname.substring(1).split("/");
  const isAdminPage = currentRouteArray.includes("admin");

  const nameMappings = new Map<string, string>([
    ["api-keys", "API Keys"],
    ["admin", "Geoscape Admin"],
    ["demos", "Demos & Prototypes"],
    ["management", "Customer Management"],
    ["explorer", ""],
    ["base", ""],
    ["demos/base", ""],
    ["property-insights", ""],
    ["security", "User Security"],
  ]);

  const routeMappings: any = {
    "/admin": "/admin/dashboard",
    "/admin/customer-management": "/admin/customer-management/accounts",
    "/admin/release-management": "/admin/release-management/releases",
    "/admin/release-management/edit-release":
      "/admin/release-management/releases",
  };

  const historyProps = (location.state as HistoryProps) ?? {
    accountId: undefined,
    accountName: undefined,
  };

  let currentPageName =
    nameMappings.get(currentRouteArray[currentRouteArray.length - 1]) ??
    _.startCase(currentRouteArray[currentRouteArray.length - 1]);
  if (location.pathname.includes(Routes.adminAccountDetailsPath)) {
    currentPageName = historyProps.accountName ?? "";
  }

  const formatBreadcrumbSegment = (routeSegment: string) => {
    /**
     * Format according to the following order
     * 1. Replace accountId with accountName (when admins view user accounts)
     * 2. Replace from the nameMappings dictionary
     * 3. Use start case formatting
     */

    if (historyProps.accountId && routeSegment === historyProps.accountId) {
      return historyProps.accountName;
    } else if (nameMappings.get(routeSegment) !== undefined) {
      return nameMappings.get(routeSegment);
    } else {
      return _.startCase(routeSegment);
    }
  };

  return (
    <Box sx={{ zIndex: "1000" }}>
      <Typography variant="h6">{currentPageName}</Typography>
      {isAdminPage && !currentRouteArray.includes("property-insights") && (
        <Breadcrumbs separator="/" aria-label="breadcrumb">
          {currentRouteArray.map((item, index) => {
            if (index + 1 === currentRouteArray.length) {
              return <Box key={index}>{formatBreadcrumbSegment(item)}</Box>;
            } else {
              const currentRoute =
                "/" + currentRouteArray.slice(0, index + 1).join("/");

              return (
                <Link
                  key={index}
                  to={{
                    pathname: routeMappings[currentRoute] || currentRoute,
                    state: history.location.state as Object,
                  }}
                  style={{ color: "inherit" }}
                >
                  {formatBreadcrumbSegment(item)}
                </Link>
              );
            }
          })}
        </Breadcrumbs>
      )}
    </Box>
  );
};
