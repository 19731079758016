import { Alert, Grid, Skeleton, Stack, useTheme } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { ReactQueryQueryError, fetchDemosData } from "data/queries";
import _ from "lodash";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { DemoCard } from "./demo-card";

export const DemosPage = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const demosDefinitionsQuery = useQuery<any>(
    [QueryKeys.demosKey, identityId],
    () => fetchDemosData(identityState)
  );

  const isLoading: boolean = demosDefinitionsQuery.isLoading;
  const isError: boolean = demosDefinitionsQuery.isError;
  const isSuccess: boolean = demosDefinitionsQuery.isSuccess;

  const skeletonArray: number[] = _.range(1, 18);

  let dataDemos = demosDefinitionsQuery.data ? demosDefinitionsQuery.data : [];
  return (
    <Stack direction="column" gap={2}>
      <Grid container spacing={2}>
        {isError && (
          <Alert severity="error">
            {(demosDefinitionsQuery.error as ReactQueryQueryError).message}
          </Alert>
        )}
        {isLoading &&
          skeletonArray.map((i) => {
            return (
              <Grid item md={12} lg={6} key={i}>
                <Skeleton variant="rectangular" height="250px" />
              </Grid>
            );
          })}
        {isSuccess &&
          dataDemos.map((demoItem: any, index: number) => {
            return (
              <Grid item md={12} lg={6} key={`${index}`}>
                <DemoCard demoItem={demoItem}></DemoCard>
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
};
