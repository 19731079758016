import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Auth } from "aws-amplify";
import { Form, Formik, FormikConfig } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Routes from "routes";
import { boolean, InferType, object, ref, string } from "yup";
import { useMessageState } from "./use-message-state";

export const forgotPasswordValidationSchema = object({
  isDelivered: boolean(),
  email: string().email("Enter a valid email.").required("Email is required."),
  code: string().when("isDelivered", {
    is: true,
    then: string()
      .matches(/^[0-9]+$/, "Must be only digits.")
      .min(6, "Must be exactly 6 digits.")
      .max(6, "Must be exactly 6 digits.")
      .required("Code is required"),
  }),
  newPassword: string().when("isDelivered", {
    is: true,
    then: string()
      .min(8, "New Password should be of minimum 8 characters length")
      .required("New Password is required."),
  }),
  confirmNewPassword: string().when("isDelivered", {
    is: true,
    then: string()
      .oneOf([ref("newPassword"), null], "Passwords must match")
      .required("Confirm New Password is required."),
  }),
});

type ResetPasswordValues = InferType<typeof forgotPasswordValidationSchema>;

export const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useMessageState({
    passwordReset: false,
    type: null,
    text: "",
  });

  const onSubmit: FormikConfig<
    InferType<typeof forgotPasswordValidationSchema>
  >["onSubmit"] = async (
    { email, isDelivered, code, newPassword },
    { setFieldValue }
  ) => {
    try {
      setIsLoading(true);
      if (!isDelivered) {
        await Auth.forgotPassword(email.toLowerCase());
        setFieldValue("isDelivered", true, false);
        setMessage({
          type: "success",
          text: `A verification code has been successfully sent to ${email}.`,
        });
      } else {
        await Auth.forgotPasswordSubmit(
          email.toLowerCase(),
          code!,
          newPassword!
        );
        setMessage({
          passwordReset: true,
          type: "success",
          text: `Your password has been successfully reset.`,
        });
        // history.goBack();
      }
    } catch (e: any) {
      setMessage({
        type: "error",
        text: e?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik<ResetPasswordValues>
        initialValues={{
          isDelivered: false,
          email: "",
          code: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={forgotPasswordValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          values: { isDelivered, email, code, newPassword, confirmNewPassword },
          handleChange,
          touched,
          errors,
        }) => (
          <Form method="POST">
            <Card style={{ padding: "16px", paddingBottom: "0px" }}>
              <Typography variant="h5" color="text.primary">
                Password Reset
              </Typography>
              {message.passwordReset ? (
                <>
                  {" "}
                  <Box marginBottom={2} marginTop={2}>
                    <Alert variant="outlined" severity={"success"}>
                      {message.text}
                    </Alert>
                  </Box>{" "}
                  <Divider />
                  <Button
                    style={{ padding: "16px" }}
                    LinkComponent={Link}
                    disabled={isLoading}
                    size="small"
                    fullWidth
                    href={Routes.signIn}
                  >
                    Sign In
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="body2" color="text.secondary">
                    {isDelivered
                      ? "Complete the form with the 'forgot password' code and a new password."
                      : "Don't worry, it happens to the best of us"}
                  </Typography>

                  <TextField
                    fullWidth
                    margin="normal"
                    id="email"
                    name="email"
                    label="Email"
                    value={email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    disabled={isDelivered || isLoading}
                  />
                  {isDelivered && (
                    <>
                      <TextField
                        fullWidth
                        margin="normal"
                        id="code"
                        name="code"
                        label="Code"
                        type="text"
                        value={code}
                        onChange={handleChange}
                        error={touched.code && Boolean(errors.code)}
                        helperText={touched.code && errors.code}
                        disabled={isLoading}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        id="newPassword"
                        name="newPassword"
                        label="New Password"
                        type={showPassword ? "text" : "password"}
                        value={newPassword}
                        onChange={handleChange}
                        error={
                          touched.newPassword && Boolean(errors.newPassword)
                        }
                        helperText={touched.newPassword && errors.newPassword}
                        disabled={isLoading}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        label="Confirm New Password"
                        type={showPassword ? "text" : "password"}
                        value={confirmNewPassword}
                        onChange={handleChange}
                        error={
                          touched.confirmNewPassword &&
                          Boolean(errors.confirmNewPassword)
                        }
                        helperText={
                          touched.confirmNewPassword &&
                          errors.confirmNewPassword
                        }
                        disabled={isLoading}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </>
                  )}
                  <Box marginY={2}>
                    {isLoading ? (
                      <Box
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        {message.text === "" || !message.type ? null : (
                          <Box marginBottom={2} marginTop={2}>
                            <Alert variant="outlined" severity={message.type}>
                              {message.text}
                            </Alert>
                          </Box>
                        )}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: "16px",
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                            fullWidth
                            children="Submit"
                          />
                          <Divider />

                          <Button
                            LinkComponent={Link}
                            disabled={isLoading}
                            size="small"
                            fullWidth
                            href={Routes.signIn}
                          >
                            Sign In
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Card>
          </Form>
        )}
      </Formik>
      <Divider />

      {/* <Box>
        <Link href="sign-in">Sign in</Link>
      </Box> */}
    </>
  );
};
