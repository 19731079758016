import { Layer } from "react-map-gl";
import { hexToHSL } from "../layer-utils";
import { useEffect, useState } from "react";
import { LayerProps } from "../explorer-display-layers";
import { HSLToHSLString, highlightTypeForGeo } from "./paint";
import { highlightPaintForGeo } from "./paint";
import { useTheme } from "@mui/material";

export function SecondaryHighlightLayer(props: LayerProps) {
  const [beforeId, setBeforeId] = useState(props.beforeId ?? "");
  const theme = useTheme();

  useEffect(() => {
    // Check if the referenced layer has been rendered
    const referencedLayer = document.getElementById(beforeId);

    if (!referencedLayer) {
      // If the referenced layer hasn't been rendered yet, update the beforeId in the state
      setBeforeId(props.beforeId ?? "");
    }
  }, [props.beforeId]);

  const layerHSL = hexToHSL(props.layerConfig.styles.colour ?? "#FFFFFF");
  const highlightHSL = { ...layerHSL };

  const highlightSaturationOffset = theme.palette.mode === "dark" ? 50 : -50;
  highlightHSL.s += highlightSaturationOffset;

  const highlightLayerPaint = props.layerConfig
    ? highlightPaintForGeo(
        HSLToHSLString(highlightHSL),
        1,
        props.layerConfig.geometry,
        2
      )
    : {};

  return (
    <Layer
      key={props.id}
      layout={{
        visibility: props.layerConfig.visible ? "visible" : "none",
      }}
      beforeId={beforeId}
      id={props.id}
      type={highlightTypeForGeo(props.layer.geometry)}
      source={props.source.description}
      source-layer={props.layer.id}
      paint={highlightLayerPaint}
      //@ts-ignore
      filter={props.layerConfig.secondaryHighlightsFilter}
    />
  );
}
