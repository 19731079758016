import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Icon, Link, ListItemButton, Tooltip } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const drawerWidth = 256;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerSideBarHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "8px",
}));

export const DrawerSideBarFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "8px",
}));

export const DrawerSideBar = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),

  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type MenuItem = {
  link: string;
  target?: string;
  Icon?: React.ElementType;
  name: string;
};

type Props = {
  sideMenu: MenuItem[];
  currentRoute: string;
  open: boolean;
};

export const RenderMenu = ({ sideMenu, currentRoute, open }: Props) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  return (
    <>
      <List>
        {sideMenu.map((item: any, index: any) => (
          <ListItem
            selected={currentRoute === item.link}
            key={index}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              href={item.link}
              component={item["target"] ? ListItemButton : Link}
              target={item["target"]}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              id={`menuitem-${item["name"].toLowerCase().replaceAll(" ", "-")}`}
            >
              {!!item.Icon && (
                <Tooltip title={open ? "" : item.name} arrow placement="right">
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Icon>
                      <item.Icon fill={isDark ? "white" : "rgba(98,98,98,1)"} />
                    </Icon>
                  </ListItemIcon>
                </Tooltip>
              )}

              <ListItemText
                primary={item.name}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};
