import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  InputLabel,
  Skeleton,
  Typography,
} from "@mui/material";
import ConfirmationModal from "components/modals/confirmation-modal";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { quoteClip } from "data/mutations";
import {
  fetchClipSummary,
  fetchOverage,
  fetchSubscription,
} from "data/queries";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useIntercom } from "react-use-intercom";
import { openDownloadLinkCallback } from "utils/download";
import { resolveIdentityId } from "utils/identity";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import { ClipConfiguration } from "../../models";
import { PurchaseWithCredits } from "./purchase-with-credits";
import { PurchaseWithDollars } from "./purchase-with-dollars";
import { SummaryTable } from "./summary-table";

export const ClipSummary = (props: ClipConfigProps & ClipStepsControlProps) => {
  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const { enqueueQueryFailed, enqueueMutationFailed } = useCustomSnackbars();

  const clipSummaryQuery = useQuery(
    [QueryKeys.clipSummaryKey, identityId],
    () => fetchClipSummary(props.clipConfig.id, identityState),
    {
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );

  const subscriptionQuery = useQuery(
    [QueryKeys.subscription, identityId],
    () => fetchSubscription(isUser, identityState),
    {
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );

  // PRELOAD OVERAGE QUERY
  const overageQuery = useQuery([QueryKeys.overage, identityId], () =>
    fetchOverage(isUser, identityState)
  );

  const calculateDollarAmount = (credits: number, planName: string) => {
    if (planName.toLowerCase() === "enterprise") return credits / 100;
    if (planName.toLowerCase() === "professional") return credits / 100;
    if (planName.toLowerCase() === "freemium") return (credits * 15) / 1000;
    return 0;
  };

  const [subStep, setSubStep] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const isSummaryOnly = props.clipConfig.status !== "Quoted";
  const timeDelta = dayjs().utc().unix() - (props.clipConfig.quotedOn ?? 0);
  const isExpired = timeDelta > 5 * 24 * 60 * 60;
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const onQuoteSubmit = async () => {
    const newClip = {
      ...props.clipConfig,
      activeStep: props.activeStep - 1,
    };
    props.setClipConfig(newClip);
    props.saveClipConfig(newClip);
    clipsQuoteMutation.mutate(props.clipConfig.id);
  };

  const clipsQuoteMutation = useMutation(
    (clipId: string) => quoteClip(clipId, identityState),
    {
      onSuccess: (clipConfig: ClipConfiguration) => {
        props.setClipConfig((prev: ClipConfiguration) => {
          return {
            ...prev,
            status: clipConfig.status,
          };
        });

        queryClient.invalidateQueries({
          queryKey: [QueryKeys.clipsKey, identityId],
        }),
          enqueueSnackbar("Clip has been submitted for quoting.", {
            variant: "success",
          });
        props.resetClipConfig();
      },
      onError: (error: Error) => {
        enqueueMutationFailed(error.toString());
      },
    }
  );

  const onEdit = () => {
    const newClip: ClipConfiguration = {
      ...props.clipConfig,
      activeStep: 1,
      status: "Draft",
    };

    props.setClipConfig(newClip);
    props.setActiveStep(1);
    props.saveClipConfig(newClip);
  };
  const { show } = useIntercom();

  const hideForExpired = props.clipConfig.status === "Quoted" && isExpired;

  const clipTooBig =
    clipSummaryQuery.isSuccess &&
    subscriptionQuery.isSuccess &&
    calculateDollarAmount(
      clipSummaryQuery.data.totalCredits,
      subscriptionQuery.data.activeSubscription.plan.name
    ) > 10000;
  const isEnterprise =
    subscriptionQuery?.data?.activeSubscription.plan.name.toLowerCase() ===
    "enterprise";
  return (
    <>
      {subStep == 0 && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            maxHeight="calc(86vh - 64px)"
            overflow="auto"
          >
            <Typography variant="h5">{`${
              props.clipConfig.status === "Quoted" ? "Quote" : "Order"
            } Summary`}</Typography>
            {props.clipConfig.status === "Quoted" && !isExpired && (
              <Alert severity="info" variant="outlined">
                Quote is valid for 5 days
              </Alert>
            )}
            {props.clipConfig.status === "Quoted" && isExpired && (
              <Alert severity="error" variant="outlined">
                <AlertTitle>Quote has expired</AlertTitle>
                Please click 'Requote' button to submit this again.
              </Alert>
            )}
            {props.clipConfig.status === "Processing" && (
              <Alert severity="info" variant="outlined">
                Clip Processing, you will be notified when it's ready
              </Alert>
            )}
            <Box minHeight="43px">
              <InputLabel>Name</InputLabel>
              <Typography noWrap variant="body2">
                {props.clipConfig.name}
              </Typography>
            </Box>
            <Box minHeight="43px">
              <InputLabel>Format</InputLabel>
              <Typography variant="body2">{props.clipConfig.format}</Typography>
            </Box>
            <Box minHeight="43px">
              <InputLabel>Coordinate Reference System</InputLabel>
              <Typography variant="body2">{props.clipConfig.crs}</Typography>
            </Box>
            {props.clipConfig.clientReference && (
              <Box minHeight="43px">
                <InputLabel>Client Reference</InputLabel>
                <Typography variant="body2">
                  {props.clipConfig.clientReference}
                </Typography>
              </Box>
            )}
            {props.clipConfig.notes && (
              <Box minHeight="43px">
                <InputLabel>Notes</InputLabel>
                <Typography variant="body2">
                  {props.clipConfig.notes}
                </Typography>
              </Box>
            )}
            <Typography variant="subtitle1">Data</Typography>

            {clipSummaryQuery.isSuccess && subscriptionQuery.isSuccess && (
              <>
                <SummaryTable
                  quoteSummary={clipSummaryQuery.data}
                  showCredits={
                    !hideForExpired && !(clipTooBig && !isEnterprise)
                  }
                  showFeatures={!hideForExpired}
                  showRate={!hideForExpired && !(clipTooBig && !isEnterprise)}
                  showTotal={!hideForExpired}
                ></SummaryTable>
                {!isExpired &&
                  clipSummaryQuery.data.totalFeatures > 0 &&
                  !(clipTooBig && !isEnterprise) && (
                    <>
                      <Typography variant="h5">Quote</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {`Credits due: ${clipSummaryQuery.data.totalCredits.toLocaleString()}`}
                      </Typography>
                      {!isEnterprise && (
                        <Typography variant="subtitle2" fontWeight="bold">
                          {`Dollar due: $${calculateDollarAmount(
                            clipSummaryQuery.data.totalCredits,
                            subscriptionQuery.data.activeSubscription.plan.name
                          ).toLocaleString("en-AU", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })} inc GST`}
                        </Typography>
                      )}
                    </>
                  )}
                {clipSummaryQuery.data.totalFeatures === 0 && (
                  <Alert severity="warning">
                    The quoted area contains no features. Return to edit mode to
                    modify the clipped area.
                  </Alert>
                )}
                {clipTooBig && !isEnterprise && (
                  <Alert
                    icon={<InfoOutlinedIcon />}
                    color="info"
                    variant="outlined"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => show()}
                      >
                        Contact
                      </Button>
                    }
                  >
                    <AlertTitle>Quote too large</AlertTitle>
                    Talk to us about a commercial license, its significantly
                    better value.
                  </Alert>
                )}
              </>
            )}
            {!isSummaryOnly &&
              (clipSummaryQuery.isLoading || subscriptionQuery.isLoading) && (
                <Skeleton variant="rectangular" height={100}></Skeleton>
              )}
            {((!isSummaryOnly && clipSummaryQuery.isError) ||
              subscriptionQuery.isError) && (
              <Alert severity="error" variant="outlined">
                Unable to fetch quote summary
              </Alert>
            )}
          </Box>
          <Box
            alignSelf="flex-end"
            marginTop="auto"
            display="flex"
            paddingTop={1}
            gap={1}
          >
            {!isSummaryOnly &&
              !isExpired &&
              clipSummaryQuery.isSuccess &&
              subscriptionQuery.isSuccess && (
                <>
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => setEditModalOpen(true)}
                  >
                    Edit
                  </Button>
                  <ConfirmationModal
                    isLoading={false}
                    isError={false}
                    open={editModalOpen}
                    header="Confirm Clip Modification"
                    body="Editing this clip will cancel the current quote. A new quoting process will begin once you resubmit the clip"
                    leftButtonText={"Edit Clip"}
                    leftButtonColor="warning"
                    rightButtonText={"Cancel"}
                    rightButtonColor="primary"
                    handleLeftButton={onEdit}
                    handleRightButton={() => setEditModalOpen(false)}
                    handleClose={() => setEditModalOpen(false)}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSubStep(2);
                    }}
                    disabled={
                      subscriptionQuery.isLoading ||
                      isEnterprise ||
                      clipSummaryQuery.isLoading ||
                      clipSummaryQuery?.data?.totalFeatures === 0 ||
                      clipTooBig
                    }
                  >
                    Pay $
                  </Button>
                  <Button
                    variant="contained"
                    disabled={
                      clipSummaryQuery.isLoading ||
                      clipSummaryQuery?.data?.totalFeatures === 0 ||
                      (!isEnterprise && clipTooBig)
                    }
                    onClick={() => setSubStep(1)}
                  >
                    Pay Credits
                  </Button>
                </>
              )}
            {props.clipConfig.status === "Quoted" && isExpired && (
              <LoadingButton
                loading={clipsQuoteMutation.isLoading}
                disabled={clipsQuoteMutation.isLoading}
                onClick={async () => {
                  await onQuoteSubmit();
                }}
              >
                Requote
              </LoadingButton>
            )}
          </Box>
        </>
      )}
      {subStep == 1 &&
        clipSummaryQuery.isSuccess &&
        subscriptionQuery.isSuccess && (
          <PurchaseWithCredits
            setClipConfig={props.setClipConfig}
            resetClipConfig={props.resetClipConfig}
            clipConfig={props.clipConfig}
            quoteSummary={clipSummaryQuery.data}
            planName={subscriptionQuery.data.activeSubscription.plan.name}
            setSubStep={setSubStep}
          />
        )}
      {subStep == 2 &&
        clipSummaryQuery.isSuccess &&
        subscriptionQuery.isSuccess && (
          <PurchaseWithDollars
            clipConfig={props.clipConfig}
            quoteSummary={clipSummaryQuery.data}
            planName={subscriptionQuery.data.activeSubscription.plan.name}
            resetClipConfig={props.resetClipConfig}
            setSubStep={setSubStep}
          />
        )}
      {(props.clipConfig.status === "Processing" ||
        props.clipConfig.status === "Completed") && (
        <Box
          alignSelf="flex-end"
          marginTop="auto"
          paddingTop={1}
          display="flex"
        >
          <Button
            disabled={!props.clipConfig.invoiceUrl}
            onClick={() => {
              window.open(props.clipConfig.invoiceUrl, "_blank");
            }}
          >
            Download Invoice
          </Button>
          <Button
            disabled={props.clipConfig.status !== "Completed"}
            variant="text"
            onClick={openDownloadLinkCallback(
              `${import.meta.env.VITE_DELIVERY_API_URL}/clip/${
                props.clipConfig.id
              }/output`
            )}
          >
            Download
          </Button>
        </Box>
      )}
    </>
  );
};
