import { Button, Card, CardHeader, Popover, Typography } from "@mui/material";

// reactQuery
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { DateRangePicker } from "./date-range-picker-base";

// reactQuery
import {
    DateRange,
    DateRangeValidationError,
    PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";

const DateRangePickerComponent = ({
    isFreemium,
    label,
    setStartTime,
    setEndTime,
    setLabel,
}: any) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleDateRangeChange = (
        value: DateRange<Dayjs>,
        context: PickerChangeHandlerContext<DateRangeValidationError>
    ) => {
        if (value[0] && value[1]) {
            // Start time at the beginning of the first selected day
            const startTime = value[0].startOf("day").unix() * 1000;
            // End time at the end of the last selected day
            const endTime = value[1].endOf("day").unix() * 1000;

            setStartTime(startTime);
            setEndTime(endTime);
            handleClose();
            if (context.shortcut) {
                setLabel(context.shortcut.label);
            } else {
                const startDay = dayjs(value[0]).format("MMM DD");
                const endDay = dayjs(value[1]).format("MMM DD");
                setLabel(`${startDay} - ${endDay}`);
            }
        }
    };

    return (
        <Card
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: 2,
                width: "50%",
            }}
        >
            <CardHeader
                sx={{ width: "60%" }}
                title="Set Date range"
                subheader={
                    isFreemium && (
                        <Typography variant="body2">
                            Freemium can only report to a maximum of 90 days in the past.
                        </Typography>
                    )
                }
            ></CardHeader>
            <Button
                size="small"
                variant="contained"
                onClick={handleClick}
                startIcon={<CalendarTodayIcon />}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {label}
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <DateRangePicker onChange={handleDateRangeChange} />
            </Popover>
        </Card>
    );
};

export default DateRangePickerComponent;
