import Crop32Icon from "@mui/icons-material/Crop32";
import ImageIcon from "@mui/icons-material/Image";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PolylineIcon from "@mui/icons-material/Polyline";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import { ShowMore } from "components/shared/show-more";

import emissionsMetric from "icons/emissionsMetric.png";
import addressAutocomplete from "icons/addressAutocomplete.png";
import geoscapeChatswood from "icons/geoscapeChatswood.png";

const thumbnail: { [key: string]: string } = {
  "Emissions Metric": emissionsMetric,
  "Address Autocomplete": addressAutocomplete,
  "Geoscape Chatswood": geoscapeChatswood,
};
export const DemoCard = (props: any) => {
  const data = props.demoItem;

  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CardActionArea
        href={`${data.href}`}
        disabled={data.title == "Emissions Metric"}
        target="_blank"
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardMedia
          sx={{ height: 250, width: 250 }}
          component="img"
          src={thumbnail[data.title]}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: 2,
              //  alignSelf: "flex-start",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <CardHeader
              sx={{
                padding: 0,
              }}
              title={data.title}
            ></CardHeader>
            <Typography variant="body1">{data.type}</Typography>
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Box sx={{ width: "150px", display: "flex", gap: "8px" }}>
                {data.categories.map((type: any) => (
                  <Chip label={type} color="primary" size="small" />
                ))}
              </Box>
            </Box>
            <ShowMore lines={3} showButton={false}>
              <Typography variant="body2">{data.description}</Typography>
            </ShowMore>
          </CardContent>
          <CardActions
            sx={{
              gap: 1,
            }}
          >
            <Button
              size="small"
              color="primary"
              disabled={data.title == "Emissions Metric"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(data.href, "_blank");
              }}
            >
              Start
            </Button>
          </CardActions>
        </Box>
      </CardActionArea>
    </Card>
  );
};
