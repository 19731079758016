import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as QueryKeys from "data";

import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DynamicBreadcrumbsMenu } from "components/breadcrumbs/dynamic-breadcrumbs";
import FadeMenu from "components/navigation/menu-bar";
import SideBarSubMenu from "components/navigation/side-bar-sub-menu";
import {
  adminSettingsPageMenuItems,
  dataMenuItems,
  developerMenuItems,
  getMenuItems,
  orgSettingsPageMenuItems,
  userSettingsPageMenuItems,
} from "components/navigation/utils-side-bar";
import {
  getActiveOrganisation,
  isAdminIdentity,
  useIdentity,
} from "contexts/identity-context";
import {
  fetchApiKeys,
  fetchApiProducts,
  fetchBillingUsage,
  fetchDefinitions,
  fetchOverage,
  fetchPaymentMethod,
  fetchSubscription,
} from "data/queries";
import HelpSvg from "icons/question-fill.svg?react";
import * as React from "react";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Route, Switch, useLocation } from "react-router";
import * as Routes from "routes";
import { RouteSwitch } from "routes/route-switch";
import { resolveIdentityId } from "utils/identity";
import { Intercom } from "./boot-intercom";
import {
  DrawerSideBar,
  DrawerSideBarFooter,
  DrawerSideBarHeader,
  RenderMenu,
} from "./side-bar";

export const SignedIn = () => {
  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery([QueryKeys.definitionsKey], () =>
      fetchDefinitions(identityState)
    );
    queryClient.prefetchQuery([QueryKeys.apiKeys, identityId], () =>
      fetchApiKeys(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.apiProducts], () =>
      fetchApiProducts(identityState)
    );
    queryClient.prefetchQuery([QueryKeys.subscription, identityId], () =>
      fetchSubscription(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.billingUsage, identityId], () =>
      fetchBillingUsage(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.paymentMethod, identityId], () =>
      fetchPaymentMethod(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.overage, identityId], () =>
      fetchOverage(isUser, identityState)
    );
  }, [identityId]);

  /**
   * App in a signed out state.
   * The "Geo-Sidebar" with our menu items on the left.
   * On the right, a paper with the context for whatever route we're on
   */

  return (
    <>
      <Intercom />
      {/* MAIN BOX */}
      <Box
        style={{
          display: "block",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <MainLayout />
      </Box>
    </>
  );
};

export default function MainLayout() {
  // This component divied the layout in two LEFT - RIGHT
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [identityState] = useIdentity();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const currentRoute = useLocation().pathname;

  const isUser = !getActiveOrganisation(identityState);
  const isLight = theme.palette.mode === "light";

  const sideMenu = getMenuItems(isUser).filter((item, index) => {
    if (item.adminOnly) {
      return isAdminIdentity(identityState);
    }
    return true;
  });

  useEffect(() => {
    setOpen(currentRoute === Routes.hub);
  }, [currentRoute]);

  return (
    <Box
      sx={{ display: "flex" }}
      style={{
        // display: "block",
        width: "100%",
        height: "100%",
        // backgroundColor: "red",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <CssBaseline />

      {/* LEFT CONTAINER - SIDEBAR */}
      <DrawerSideBar
        variant="permanent"
        open={open}
        style={{
          // backgroundColor: "yellow",
          padding: "16px",
        }}
      >
        {/* HEADER - DISPLAY ACCOUNT/USER NAME - MENU */}
        <DrawerSideBarHeader>
          <FadeMenu />
        </DrawerSideBarHeader>

        {/* MENU */}
        <RenderMenu
          sideMenu={sideMenu}
          currentRoute={currentRoute}
          open={open}
        />

        <Box style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <RenderMenu
            sideMenu={[
              {
                name: "Help",
                link: "https://geoscape.com.au/support/",
                Icon: HelpSvg,
                target: "_blank",
              },
            ]}
            currentRoute={currentRoute}
            open={open}
          />

          <DrawerSideBarFooter>
            {open ? (
              <>
                <IconButton
                  onClick={handleDrawerClose}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "254px",
                    borderRadius: "0px",
                    gap: "24px",
                  }}
                >
                  <ChevronLeftIcon
                    style={{
                      marginLeft: "12px",
                    }}
                  />
                  <Typography variant="body1">Collapse</Typography>
                </IconButton>
              </>
            ) : (
              <>
                <IconButton onClick={handleDrawerOpen}>
                  {theme.direction === "rtl" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </>
            )}
          </DrawerSideBarFooter>
        </Box>
      </DrawerSideBar>
      {/* ROUTES TO DISPLAY SECOND SIDEBAR */}
      <Switch>
        {/* Developer  SideBar */}
        <Route
          exact
          path={[
            Routes.developerDashboard,
            Routes.developerApiKeys,
            Routes.developerApiKeysCreateKey,
            Routes.developerApiKeysEditKey,
            Routes.developerUsage,
          ]}
          render={(props) => <SideBarSubMenu menuItems={developerMenuItems} />}
        />

        {/* Data  SideBar */}
        <Route
          exact
          path={[
            Routes.dataDashboard,
            Routes.dataExplorer,
            Routes.dataCatalogue,
            Routes.dataCatalogueEntry,
            Routes.dataCustomDownload,
            Routes.dataDownload,
            Routes.dataTailor,
          ]}
          render={(props) => <SideBarSubMenu menuItems={dataMenuItems} />}
        />

        {/* Org Settings SideBar */}
        <Route
          exact
          path={[
            Routes.orgGeneralSettings,
            Routes.orgMembers,
            Routes.orgSubscription,
            Routes.orgOverage,
            Routes.orgReceipts,
            Routes.orgBillingDetails,
            Routes.orgManageSubscription,
          ]}
          render={(props) => (
            <SideBarSubMenu menuItems={orgSettingsPageMenuItems} />
          )}
        />
        {/* User Settings SideBar */}
        <Route
          exact
          path={[
            Routes.userGeneralSettings,
            Routes.userSecurity,
            Routes.userCreateOrganisation,
            Routes.userSubscription,
            Routes.userPendingInvitations,
            Routes.legal,
            Routes.userOverage,
            Routes.userReceipts,
            Routes.userBillingDetails,
            Routes.userManageSubscription,
          ]}
          render={(props) => (
            <SideBarSubMenu menuItems={userSettingsPageMenuItems} />
          )}
        />
        {/* Admin Settings SideBar */}
        <Route
          exact
          path={[
            Routes.adminDashboard,
            Routes.adminAccount,
            Routes.adminUser,
            Routes.adminMergeAccounts,
            Routes.adminMigrateKeys,
            Routes.adminCustomerUsage,
            Routes.adminOnDemand,
            Routes.adminReceipts,
            Routes.adminBillingDetails,
            Routes.adminReleases,
            Routes.adminCreateRelease,
            Routes.adminEditRelease,
            Routes.adminDatasets,
            Routes.adminAccountSubscription,
            Routes.adminAccountDetails,
            Routes.adminApiKeys,
            Routes.adminCreateApiKey,
            Routes.adminEditApiKey,
            Routes.adminViewCustomerCustomDatasets,
            Routes.adminCreateCustomerCustomDatasets,
            Routes.adminOrgMembers,
            Routes.propertyInsights,
          ]}
          render={(props) => (
            <SideBarSubMenu menuItems={adminSettingsPageMenuItems} />
          )}
        />
      </Switch>
      {/* RIGHT CONTAINER */}

      {/* Flex Container, it contains 2 Containers, one for the menu-name-dashboards and  for -footbar*/}
      <Box
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <Box
          display="flex"
          style={{
            position: "relative",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "100%",
            width: "100%",
            padding: "64px",
            gap: "24px",
            top: "0px",
            overflowY: "auto",
            backgroundColor: isLight ? "#F9F9F9" : undefined,
          }}
        >
          <DynamicBreadcrumbsMenu />

          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "1200px",
              },
            }}
          >
            <RouteSwitch></RouteSwitch>
          </Box>
          {/*  */}
          {/*  */}
        </Box>
      </Box>
    </Box>
  );
}
