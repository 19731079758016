import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis } from "recharts";
import * as Routes from "routes";

import { Close as CloseIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import { StepComponentProps } from "./types";

const data2 = [
  { name: "Online", value: 100, fill: "#FFD700" },
  { name: "Combini", value: 200, fill: "#FF6347" },
  { name: "Supermarket", value: 300, fill: "#FFA500" },
  { name: "", value: 700, fill: "#F5F5F5" },
];

const dummyData = [
  { country: "AE", value: 152, value2: 112, value3: 30 },
  { country: "AF", value: 154, value2: 119, value3: 35 },
  { country: "AG", value: 118, value2: 199, value3: 101 },
  { country: "AI", value: 171, value2: 74, value3: 189 },
  { country: "AL", value: 121, value2: 143, value3: 200 },
  { country: "AM", value: 113, value2: 56, value3: 102 },
];

const data = [
  {
    name: "2018",
    burger: 152,
    sandwich: 186,
    kebab: 160,
    fries: 0,
    donut: 0,
    total: 498,
  },
  {
    name: "2019",
    burger: 152,
    sandwich: 0,
    kebab: 112,
    fries: 30,
    donut: 0,
    total: 294,
  },
  {
    name: "2020",
    burger: 0,
    sandwich: 154,
    kebab: 119,
    fries: 35,
    donut: 91,
    total: 399,
  },
  {
    name: "2021",
    burger: 199,
    sandwich: 32,
    kebab: 101,
    fries: 0,
    donut: 183,
    total: 515,
  },
  {
    name: "2022",
    burger: 74,
    sandwich: 119,
    kebab: 189,
    fries: 69,
    donut: 0,
    total: 451,
  },
  {
    name: "2023",
    burger: 143,
    sandwich: 98,
    kebab: 200,
    fries: 0,
    donut: 78,
    total: 519,
  },
  {
    name: "2024",
    burger: 56,
    sandwich: 159,
    kebab: 0,
    fries: 102,
    donut: 105,
    total: 422,
  },
  {
    name: "2024",
    burger: 56,
    sandwich: 159,
    kebab: 0,
    fries: 102,
    donut: 105,
    total: 422,
  },
  {
    name: "2024",
    burger: 56,
    sandwich: 159,
    kebab: 0,
    fries: 102,
    donut: 105,
    total: 422,
  },
  {
    name: "2024",
    burger: 56,
    sandwich: 159,
    kebab: 0,
    fries: 102,
    donut: 195,
    total: 422,
  },
  {
    name: "2024",
    burger: 56,
    sandwich: 159,
    kebab: 0,
    fries: 102,
    donut: 111,
    total: 422,
  },
  {
    name: "2024",
    burger: 56,
    sandwich: 159,
    kebab: 0,
    fries: 112,
    donut: 105,
    total: 422,
  },
];

export const MandatoryReportingInfo = (props: StepComponentProps) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Card
        style={{
          border: "none",
          boxShadow: "none",
          width: "450px",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignSelf: "self-end",
          position: "fixed",
          left: "40%",
          bottom: "30%",
        }}
      >
        <CardHeader
          title={props.data.title}
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <Typography variant="body1" paragraph>
            {props.data.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            style={{ marginRight: 8 }}
            onClick={() => {
              history.push(Routes.demosPage);
            }}
          >
            Close
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              props.onSelectAddress({
                ...props.nextCoordinates,
                geocoder_attributes: {},
                zoomLevel: props.zoomLevel,
              });
              props.nextStep();
            }}
          >
            Next
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

const useStyles = makeStyles({
  minimized: {
    width: "40px",
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
});

export const EmissionMetricsCard = (props: StepComponentProps) => {
  return (
    <Card
      style={{
        // border: "none",
        // boxShadow: "none",
        // width: "450px",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "self-end",
        position: "fixed",
        bottom: "calc(0px + 64px + 10px)", // footer + offset
        left: "10%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "550px",
          padding: "10px",
        }}
      >
        <BarChart width={250} height={300} data={dummyData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="country" />
          {/* <YAxis /> */}
          <Tooltip />
          <Legend />
          <Bar dataKey="value" stackId="a" fill="#8884d8" />
          <Bar dataKey="value2" stackId="a" fill="#82ca9d" />
          <Bar dataKey="value3" stackId="a" fill="#ffc658" />
        </BarChart>
        <Box
          sx={{
            width: "300px",
            padding: "0px",
          }}
        >
          <CardHeader
            title={props.data.title}
            sx={{
              padding: "0px",
              paddingLeft: "16px",
            }}
          />
          <CardContent
            sx={{
              paddingRight: "0px",
            }}
          >
            <Typography variant="body2" paragraph>
              {props.data.description}
            </Typography>
            <List style={{ paddingLeft: 16 }}>
              <ListItem sx={{ padding: "0px" }}>
                <ListItemText secondary="Scope 1: Direct emissions" />
              </ListItem>
              <ListItem sx={{ padding: "0px" }}>
                <ListItemText secondary="Scope 2: Indirect emissions" />
              </ListItem>
              <ListItem sx={{ padding: "0px" }}>
                <ListItemText secondary="Scope 3: All other indirect emissions" />
              </ListItem>
            </List>
          </CardContent>
        </Box>
      </Box>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            props.onSelectAddress({
              ...props.previousCoordinates,
              geocoder_attributes: {},
              zoomLevel: props.previousZoomLevel,
            });

            props.backStep();
          }}
        >
          Back
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => {
            props.onSelectAddress({
              ...props.nextCoordinates,
              geocoder_attributes: {},
              zoomLevel: props.zoomLevel,
            });

            props.nextStep();
          }}
        >
          Next
        </Button>
      </CardActions>
    </Card>
  );
};

// export default EmissionMetricsCard;

// Third Card
export const FoodSalesChart = (props: StepComponentProps) => {
  const { show } = useIntercom();

  const history = useHistory();
  return (
    <Card
      style={{
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "self-end",
        position: "fixed",
        bottom: "calc(0px + 64px + 10px)", // footer + offset
        left: "10%",
        userSelect: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "550px",
          padding: "10px",
        }}
      >
        <CardHeader
          title="Food Sales Over Time"
          sx={{
            padding: "0px",
            paddingLeft: "16px",
          }}
        />
        <CardContent
          sx={{
            padding: "0px",
          }}
        >
          <BarChart width={550} height={250} data={data} barSize={15}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend align="right" layout="vertical" />
            <Bar dataKey="burger" stackId="a" fill="#e57373" name="Burger" />
            <Bar dataKey="sandwich" stackId="a" fill="purple" name="Sandwich" />
            <Bar dataKey="kebab" stackId="a" fill="#ffb74d" name="Kebab" />
            <Bar dataKey="fries" stackId="a" fill="#81c784" name="Fries" />
            <Bar dataKey="donut" stackId="a" fill="#4fc3f7" name="Donut" />
          </BarChart>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              props.onSelectAddress({
                ...props.previousCoordinates,
                geocoder_attributes: {},
                zoomLevel: props.previousZoomLevel,
              });
              props.backStep();
            }}
          >
            Back
          </Button>
          <Button
            size="small"
            color="secondary"
            onClick={() => {
              history.push(Routes.demosPage);
            }}
          >
            Close
          </Button>
          <Button size="small" color="info" onClick={() => show()}>
            Talk to an Expert
          </Button>
          <Button size="small" color="success" onClick={() => props.nextStep()}>
            Free Explore
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};

export const PropertyDetailsCard = (props: StepComponentProps) => {
  const history = useHistory();
  const { show } = useIntercom();

  return (
    <>
      <Card
        style={{
          border: "none",
          boxShadow: "none",

          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignSelf: "self-end",
          position: "fixed",
          bottom: "calc(0px + 64px + 10px)", // footer + offset
          left: "10%",
        }}
      >
        <CardActions>
          <Button
            size="small"
            color="warning"
            onClick={() => {
              history.push(Routes.demosPage);
            }}
          >
            Close
          </Button>
          <Button size="small" color="info" onClick={() => show()}>
            Talk to an Expert
          </Button>
        </CardActions>
      </Card>
      {props.attributes.source == `layer-${props.id}` && (
        <>
          <Card
            style={{
              border: "none",
              boxShadow: "none",
              width: "450px",
              maxHeight: "90%",
              display: "flex",
              flexDirection: "column",
              alignSelf: "self-end",
              position: "fixed",
              bottom: "calc(0px + 64px + 10px)", // footer + offset
              right: "10%",
            }}
          >
            <CardHeader
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    props.handleMarker();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              title="34 MOSELY ST, STRATHFIELD NSW 2135"
              subheader="GANSW706010445"
            />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <RadialBarChart
                      width={400}
                      height={300}
                      innerRadius="10%"
                      outerRadius="100%"
                      data={data2}
                      startAngle={180}
                      endAngle={0}
                    >
                      <PolarAngleAxis
                        type="number"
                        domain={[0, 700]}
                        angleAxisId={0}
                        tick={false}
                      />
                      <RadialBar background dataKey="value" angleAxisId={0} />
                    </RadialBarChart>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Building A Details</Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography>Area</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        {/* {attributes.area} */}
                        62 m2
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Number of Floors</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">2</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Net Lettable Area</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">146 m2</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Roof Material</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">Tile</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Roof Shape</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">Hipped</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Building Height</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">7.47 m</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Eave Height</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">5.8 m</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Roof Slope</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">Hipped</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Roof Colour</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">Dim Grey #766B62</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Typography variant="h6">Building B Details</Typography>
                <Grid container>
                  {[
                    "Area",
                    "Number of Floors",
                    "Net Lettable Area",
                    "Roof Material",
                    "Roof Shape",
                    "Building Height",
                    "Eave Height",
                    "Roof Shape",
                    "Roof Colour",
                  ].map((key, index) => (
                    <React.Fragment key={key}>
                      <Grid item xs={6}>
                        <Typography>{key}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right">
                          {
                            [
                              "64.74 m2",
                              "1",
                              "47 m2",
                              "Tile",
                              "Gabled",
                              "5.29 m",
                              "4.79 m",
                              "Hipped",
                              "Eerie Black #232323",
                            ][index]
                          }
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
              <Box mt={3}>
                <Typography variant="h6">Carbon Stats</Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography>Climate Category</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">5</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Carbon Metric</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right">475</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3}>
                <Typography variant="h6">Property Details</Typography>
                <Grid container>
                  {[
                    "Property Type",
                    "Strata",
                    "Zoning",
                    "Area",
                    "Parcel",
                    "Number of Buildings",
                  ].map((key, index) => (
                    <React.Fragment key={key}>
                      <Grid item xs={6}>
                        <Typography>{key}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right">
                          {
                            [
                              "Lot",
                              "No",
                              "R2 (Low Density Residential)",
                              "813.89 m2",
                              "A/339022",
                              "2",
                            ][index]
                          }
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
