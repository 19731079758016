import * as QueryKeys from "data";
import { Form, Formik } from "formik";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useIdentity } from "contexts/identity-context";
import {
  ReactQueryQueryError,
  fetchAdminUserApiKeys,
  fetchAdminUserApiKey,
  fetchAvailableSubscriptions,
  fetchUserDetailsAdmin,
} from "data/queries";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import {
  createAdminUserApiKey,
  editAdminUserSubscription,
  editAdminUserApiKey,
  deleteAdminUserApiKey,
} from "data/mutations";
import { formatTimestamp } from "utils/format-date";
import { LoadingButton } from "@mui/lab";
import { ApiKey, PortalPlan, Status } from "data/models";
import dayjs from "dayjs";
import * as Routes from "routes";
import { HistoryState } from "../account-subscription/types";
import { ApiKeyTable } from "components/api-keys/api-keys-table";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

const AdminApiKeys = () => {
  const history = useHistory();
  const [identityState] = useIdentity();
  const queryClient = useQueryClient();

  const { accountId, accountName } = history.location.state as HistoryState;

  const adminUserApiKeysQuery = useQuery(
    [QueryKeys.adminUserApiKeys, accountId],
    () => fetchAdminUserApiKeys(accountId, identityState)
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "relative",
          top: "-70px",
        }}
      >
        <Button
          onClick={() => {
            history.push(
              generatePath(Routes.adminCreateApiKey, { accountId: accountId }),
              { accountId: accountId, accountName: accountName }
            );
          }}
          variant="contained"
        >
          Create API Key
        </Button>
      </Box>

      <Box
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[adminUserApiKeysQuery]}
          mutations={[]}
        />

        <ApiKeyTable
          data={adminUserApiKeysQuery.data}
          isLoading={adminUserApiKeysQuery.isLoading}
          apiKeyDetailsRoute={Routes.adminEditApiKey}
          accountId={accountId}
          accountName={accountName}
        />
      </Box>
    </>
  );
};

export { AdminApiKeys };
