import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { Basemap } from "components/basemap/basemap";
import { jwt } from "data";
import { AddressSearch } from "pages/geoscape-data/explorer/address-search";
import { getRequestTransformer } from "pages/geoscape-data/explorer/explorer-page";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Source,
  Layer,
  MapRef,
  Marker,
  MarkerEvent,
  FillLayer,
} from "react-map-gl";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { InsightsPanel } from "./insights-panel";
import { useQuery, useQueryClient } from "react-query";
import * as QueryKeys from "data";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { resolveIdentityId } from "utils/identity";
import {
  fetchBuildingsByAddressId,
  fetchLandParcelsCadastres,
  fetchLandParcelsProperties,
} from "data/queries";

export const PropertyInsights = () => {
  const mapRef = useRef<MapRef>();
  const [coordinates, setCoordinates] = useState({
    latitude: -35.32186,
    longitude: 149.14683,
  });
  const initialZoom: number = 7;
  const addressZoom: number = 17;

  const [dynamicZoom, setDynamicZoom] = useState(initialZoom);
  const [showMarker, setShowMarker] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const [address, setAddress] = useState("");
  const [addressId, setAddressId] = useState("");
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);
  const queryClient = useQueryClient();

  const landParcelsLayer: FillLayer = {
    id: "source-land-parcels",
    type: "fill",
    paint: {
      "fill-color": "#00ffff",
      "fill-opacity": 0.5,
    },
  };

  const buildingLayer: FillLayer = {
    id: "source-buildings",
    type: "fill",
    paint: {
      "fill-color": "#0011ff",
      "fill-opacity": 0.5,
    },
  };

  const resetCompass = () => {
    if (mapRef.current === undefined) return;
    mapRef.current.easeTo({
      bearing: 0,
      pitch: 0,
    });
  };
  const handleZoom = (zoom: number): void => {
    setDynamicZoom(zoom);
    mapRef.current?.flyTo({ zoom: zoom, duration: 500 });
  };

  const handleClearAddress = (): void => {
    setShowMarker(false);
    setShowInsights(false);
  };
  const onMarkerClick = (e: MarkerEvent): void => {
    setShowInsights(true);
  };

  const buildingsQuery = useQuery(
    [QueryKeys.buildingsKey, identityId, addressId],
    () => fetchBuildingsByAddressId(addressId, identityState),
    {
      enabled: !!addressId,
    }
  );

  const landParcelsPropertiesQuery = useQuery(
    [QueryKeys.landParcelsPropertiesKey, identityId, addressId],
    () => fetchLandParcelsProperties(addressId, identityState),
    {
      enabled: !!addressId,
    }
  );

  const landParcelsCadastresQuery = useQuery(
    [QueryKeys.landParcelsCadastresKey, identityId, addressId],
    () => fetchLandParcelsCadastres(addressId, identityState),
    {
      enabled: !!addressId,
    }
  );

  const onSelectAddress = useCallback(
    ({
      longitude,
      latitude,
      geocoder_attributes,
    }: {
      longitude: number;
      latitude: number;
      geocoder_attributes: { [key: string]: string };
    }) => {
      setCoordinates({
        latitude: latitude,
        longitude: longitude,
      });
      geocoder_attributes["longitude"] = longitude.toString();
      geocoder_attributes["latitude"] = latitude.toString();

      setAddress(geocoder_attributes["formatted_address"]);
      setAddressId(geocoder_attributes["address_identifier"]);

      mapRef.current?.flyTo({
        center: [longitude, latitude],
        duration: 2000,
        zoom: addressZoom,
        offset: [-200, 0],
      });
      setDynamicZoom(addressZoom);
      setShowMarker(true);
      setShowInsights(true);
    },
    []
  );

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [showInsights]);

  return (
    <Box display="flex" flexDirection="row">
      <Basemap
        // @ts-ignore
        mapRef={mapRef}
        latitude={coordinates.latitude}
        longitude={coordinates.longitude}
        zoom={initialZoom}
        onZoom={(zoom: any) => setDynamicZoom(zoom)}
        style={{
          width: showInsights ? "50%" : "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          display: "flex",
        }}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
      >
        {showMarker && (
          <Marker
            key={"selected-address"}
            longitude={coordinates.longitude}
            latitude={coordinates.latitude}
            // @ts-ignore
            onClick={onMarkerClick}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <LocationOnIcon fontSize="large" color="error" />
            {landParcelsPropertiesQuery.isLoading && <CircularProgress />}
          </Marker>
        )}
        <Box
          sx={{
            right: 32,
            top: 32,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            justifyContent: "flex-end",
          }}
        >
          <AddressSearch
            onSelectAddress={onSelectAddress}
            onClearAddress={handleClearAddress}
          />
          <ZoomControl
            resetCompass={resetCompass}
            dynamicZoom={dynamicZoom}
            onZoom={handleZoom}
          />
        </Box>
        {landParcelsPropertiesQuery.isSuccess && buildingsQuery.isSuccess && (
          <>
            <Source
              id="source-land-parcels"
              type="geojson"
              data={
                landParcelsPropertiesQuery.data as GeoJSON.FeatureCollection
              }
            >
              <Layer {...landParcelsLayer} />
            </Source>

            <Source
              id="source-buildings"
              type="geojson"
              data={
                {
                  type: "FeatureCollection",
                  features: buildingsQuery.data.data.map(
                    (data: any, index: number) => ({
                      type: "Feature",
                      properties: {},
                      geometry: data.footprint2d,
                    })
                  ),
                } as GeoJSON.FeatureCollection
              }
            >
              <Layer {...buildingLayer} />
            </Source>
          </>
        )}
      </Basemap>
      {showInsights && (
        <Box
          sx={{
            top: 0,
            right: 0,
            position: "absolute",
            width: "50%",
            height: "100%",
            display: "flex",
          }}
        >
          <Box overflow="scroll" width="100%">
            {landParcelsPropertiesQuery.isSuccess &&
              landParcelsCadastresQuery.isSuccess &&
              buildingsQuery.isSuccess && (
                <InsightsPanel
                  address={address}
                  addressId={addressId}
                  open={showInsights}
                  propertyData={landParcelsPropertiesQuery.data}
                  cadastreData={landParcelsCadastresQuery.data}
                  buildingData={buildingsQuery.data}
                />
              )}
            {(landParcelsPropertiesQuery.isLoading ||
              landParcelsCadastresQuery.isLoading) && (
              <Box gap={2}>
                <Skeleton variant="rectangular" height="290px" sx={{ m: 2 }} />
                <Skeleton variant="rectangular" height="260px" sx={{ m: 2 }} />
                <Skeleton variant="rectangular" height="224px" sx={{ m: 2 }} />
                <Skeleton variant="rectangular" height="270px" sx={{ m: 2 }} />
              </Box>
            )}
            {(landParcelsPropertiesQuery.isError ||
              landParcelsCadastresQuery.isError) && (
              <Alert severity="error">Unable to load data</Alert>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
