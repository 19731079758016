import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Divider,
  Link,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import { BaseState } from "common/models";
import { convertToHubUser } from "contexts/identity-context";
import { Form, Formik } from "formik";
import queryString from "query-string";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router";
import * as Routes from "routes";
import { boolean, InferType, object, string } from "yup";
import { IdentityState, useIdentity } from "../../contexts/identity-context";
import { SetMessageState, useMessageState } from "./use-message-state";
import { CognitoUser } from "@aws-amplify/auth";
import * as localStorageKeys from "data/local-storage-keys";
import { MfaLogin } from "./mfa-login";
import { MfaSetup } from "./mfa-setup";

// import background from "./Background.svg"
// import { boolean, InferType, object, string } from "yup";
const minPasswordLength = 8;

export const SignInValidationSchema = object({
  email: string().email("Enter a valid email").required("Email is required"),
  password: string()
    .min(
      minPasswordLength,
      `Password should be of minimum ${minPasswordLength} characters length`
    )
    .required("Password is required"),
  termsOfUse: boolean(),
});

type SignInValues = InferType<typeof SignInValidationSchema>;

export interface SignInState extends BaseState {
  isTermsOfUseRequired: boolean;
}

async function signIn(
  event: SignInValues,
  setState: Dispatch<SetStateAction<SignInState>>,
  setIntermediateUserState: Dispatch<SetStateAction<IdentityState>>,
  setMessage: SetMessageState,
  setMfaRequired: Dispatch<SetStateAction<boolean>>,
  setUser: Dispatch<SetStateAction<CognitoUser | null>>
) {
  setState((currentState) => ({ ...currentState, isLoading: true }));
  try {
    const cognitoUser = await Auth.signIn(
      event.email.toLowerCase(),
      event.password,
      {
        "custom:dev_t_and_c": String(event.termsOfUse),
      }
    );
    setUser(cognitoUser);
    if (
      cognitoUser.challengeName === "SMS_MFA" ||
      cognitoUser.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      setMfaRequired(true);
      return;
    }

    const user = await convertToHubUser(cognitoUser);
    setMessage({ text: "", type: null });
    setIntermediateUserState(user);
    return user;
  } catch (error: any) {
    if (error.message.includes("terms_of_use_failure")) {
      setState((currentState) => ({
        ...currentState,
        isTermsOfUseRequired: true,
      }));
      setMessage({
        type: "error",
        // text: "Terms of use has not been accepted.",
        text: "Please accept Geoscape Hub's Terms of Use to continue.",
      });
    } else {
      console.error(`User sign-in failure: ${error.message}`);
      setMessage({
        type: "error",
        text: "There was an error signing in. Please try again shortly. If this persists, contact support",
      });
    }
  } finally {
    setState((currentState) => ({ ...currentState, isLoading: false }));
  }
}

export const SignIn = () => {
  const initialState: SignInState = {
    isLoading: false,
    isError: false,
    message: "",
    isTermsOfUseRequired: false,
  };

  const [state, setState] = useState<SignInState>(initialState);

  // If we set the userState directly, it loads the signed-in dashboard component.
  // Therefore we need an intermediate state to keep the user state until we finish setting up MFA.
  const [_, setUserState] = useIdentity();
  const [intermediateUserState, setIntermediateUserState] =
    useState<IdentityState>(false);

  const [message, setMessage] = useMessageState({ type: null, text: "" });
  const { search } = useLocation();

  const setLoading = (isLoading: boolean) =>
    setState((currentState) => ({ ...currentState, isLoading }));

  useEffect(() => {
    (async function () {
      try {
        const params = queryString.parse(search);

        if (params.code && params.username) {
          setLoading(true);
          const username = String(params["username"]);
          const code = String(params["code"]);
          await Auth.confirmSignUp(username, code);
          setMessage({
            type: "success",
            text: "Your account is successfully confirmed.",
          });
        }
      } catch (e) {
        setMessage({
          type: "error",
          text: "An error occurred during account confirmation.",
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [mfaRequired, setMfaRequired] = useState(false);
  const [user, setUser] = useState<CognitoUser | null>(null);

  const optOutMfa = localStorage.getItem(localStorageKeys.optOutMfa);
  const mfaSetupRequired = user && !user.challengeName && optOutMfa !== "true";

  return (
    <>
      {!mfaRequired && !mfaSetupRequired && (
        <Formik<SignInValues>
          initialValues={{ email: "", password: "", termsOfUse: false }}
          onSubmit={async (e) => {
            await signIn(
              e,
              setState,
              setIntermediateUserState,
              setMessage,
              setMfaRequired,
              setUser
            ).then((user) => {
              if (optOutMfa == "true" && user) {
                setUserState(user);
              }
            });
          }}
          validationSchema={SignInValidationSchema}
        >
          {({
            values: { email, password, termsOfUse },
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form id="signinForm">
              <Card style={{ padding: "16px" }}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="text"
                  margin="normal"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={state.isLoading}
                  // variant="filled"
                  fullWidth
                />
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  // type="password"
                  margin="normal"
                  value={password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  disabled={state.isLoading}
                  // variant="filled"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />

                {state.isTermsOfUseRequired ? (
                  <>
                    {/* <Box marginBottom={2} marginTop={2}>
                    <Alert variant="outlined" severity={"info"}>
                      It seems you have not accepted our "terms of use" or there
                      have been changes to it since you last accepted it. Please
                      review it again and tick the box below.
                    </Alert>
                  </Box> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          id="termsOfUse"
                          name="termsOfUse"
                          checked={termsOfUse}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <>
                          {"I agree to Geoscape Hub's "}
                          <a
                            href="https://geoscape.com.au/legal/geoscape-developer-terms"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            Terms of Use
                          </a>
                        </>
                      }
                    />
                  </>
                ) : null}
                {/* <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    id="termsOfUse"
                    name="termsOfUse"
                    checked={termsOfUse}
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    
                    {"I agree to Geoscape Hub's "}
                    <a
                      href="https://geoscape.com.au/legal/geoscape-developer-terms"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}>
                      Terms of Use
                    </a>
                  </>
                }
              />
              <FormHelperText error={true}>
                {errors["termsOfUse"]}
              </FormHelperText> */}

                <Box marginY={2}>
                  {state.isLoading ? (
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {message.text === "" || !message.type ? null : (
                        <Box marginBottom={2} marginTop={2}>
                          <Alert variant="outlined" severity={message.type}>
                            {message.text}
                          </Alert>
                        </Box>
                      )}
                      <Button
                        id="sign-in"
                        variant="contained"
                        type="submit"
                        disabled={state.isLoading}
                        fullWidth
                        children="Sign In"
                      />
                    </>
                  )}
                </Box>
                <Divider />
                <Box
                  style={{
                    display: "flex",
                    gap: "12px",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    LinkComponent={Link}
                    size="small"
                    href={Routes.signUp}
                  >
                    Sign up
                  </Button>

                  <Divider orientation="vertical" flexItem />

                  <Button
                    LinkComponent={Link}
                    size="small"
                    href={Routes.resetPassword}
                  >
                    Password Reset
                  </Button>

                  <Divider orientation="vertical" flexItem />
                  <Button
                    LinkComponent={Link}
                    size="small"
                    href={Routes.resendVerification}
                  >
                    Resend Verification
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      )}
      {mfaRequired && (
        <MfaLogin
          onSubmit={async (cognitoUser) => {
            const user = await convertToHubUser(cognitoUser);
            setMessage({ text: "", type: null });
            setIntermediateUserState(user);
            setMfaRequired(false);
            setUserState(user);
          }}
          user={user}
        />
      )}
      {mfaSetupRequired && (
        <MfaSetup
          onSubmit={() => setUserState(intermediateUserState)}
          onOptOut={() => setUserState(intermediateUserState)}
          cognitoUser={user}
        />
      )}
    </>
  );
};
