import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { CardElement } from "@stripe/react-stripe-js";
import { PaymentMethod } from "data/models";
import { ReactQueryMutationError } from "data/mutations";
import { UseMutationResult } from "react-query";
import { Link } from "react-router-dom";

interface EditPaymentMethodDialogueProps {
  open: boolean;
  handleOnClose: () => void;
  stripeUrl: string;
  paymentMethodMutation: UseMutationResult<
    PaymentMethod,
    ReactQueryMutationError,
    void,
    unknown
  >;
}

export const EditPaymentMethodDialogue = (
  props: EditPaymentMethodDialogueProps
) => {
  return (
    <Dialog open={props.open} onClose={() => props.handleOnClose()}>
      <Box style={{ width: "450px" }}>
        <DialogTitle>
          Update Credit Card
          <Typography variant="body2">
            Payments are processed securely through{" "}
            <Link to={{ pathname: props.stripeUrl }}>Stripe</Link>.
          </Typography>
        </DialogTitle>
        <>
          <DialogContent>
            <Box
              style={{
                padding: "10px",
                border: "solid",
                borderRadius: 5,
                borderColor: "text.secondary",
                borderWidth: "1px",
                marginBottom: "5px",
              }}
            >
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: { base: { color: "inherit" } },
                }}
              />
            </Box>
            <Typography color="error">
              {props.paymentMethodMutation.isError &&
                props.paymentMethodMutation.error.message}
            </Typography>
          </DialogContent>
          <hr />
          <DialogActions>
            <Box sx={{ position: "relative", marginRight: "10px" }}>
              <LoadingButton
                variant="outlined"
                size="small"
                loading={props.paymentMethodMutation.isLoading}
                onClick={(event) => {
                  props.paymentMethodMutation.mutate();
                }}
              >
                Save Changes
              </LoadingButton>
            </Box>
            <Button
              variant="outlined"
              size="small"
              color="warning"
              disabled={props.paymentMethodMutation.isLoading}
              onClick={() => props.handleOnClose()}
            >
              CANCEL
            </Button>
          </DialogActions>
        </>
      </Box>
    </Dialog>
  );
};
