import * as Routes from "../../routes";
interface item {
  name: string;
  link?: string;
  notImplemented?: boolean;
  target?: string;
  disabled?: boolean;
}
export interface SideBarSubMenuItemProps {
  name: string;
  link?: string;
  notImplemented?: boolean;
  target?: string;
  Icon?: React.ComponentType<any>;
  items?: item[];
  disabled?: boolean;
}

export interface SideBarSubMenuProps {
  menuItems?: SideBarSubMenuItemProps[];
}

import DeveloperSvg from "icons/code-box-fill.svg?react";
import BatchSvg from "icons/file-copy-2-fill.svg?react";
import HubSvg from "icons/home-3-fill.svg?react";
import DemosSvg from "icons/lightbulb-flash-fill.svg?react";
import SettingsSvg from "icons/settings-3-fill.svg?react";
import DataSvg from "icons/stack-fill.svg?react";

import AdminSvg from "icons/admin-fill.svg?react";

import { useTheme } from "@mui/material";

export const getMenuItems = (isUser: boolean) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  var menuItems = [
    {
      name: "Hub",
      link: Routes.hub,
      Icon: HubSvg,
      // Icon: GeoscapeDeveloperSvg,
    },
    {
      name: "Data",
      link: Routes.dataExplorer,
      Icon: DataSvg,
    },
    {
      name: "Developer",
      link: Routes.developerDashboard,
      Icon: DeveloperSvg,
    },
    {
      name: "Batch",
      link: Routes.batchBatches,
      Icon: BatchSvg,
    },
    {
      name: "Demos",
      link: Routes.demosPage,
      Icon: DemosSvg,
    },
    {
      name: "Settings",
      link: isUser ? Routes.userSubscription : Routes.orgGeneralSettings,
      Icon: SettingsSvg,
    },
    {
      name: "Geoscape Admin",
      link: Routes.adminDashboard,
      Icon: AdminSvg,
      adminOnly: true,
    },
  ];

  return menuItems;
};

export const orgSettingsPageMenuItems: SideBarSubMenuItemProps[] = [
  {
    name: "Organisation",
    items: [
      {
        name: "General Settings",
        link: Routes.orgGeneralSettings,
      },
      {
        name: "Members",
        link: Routes.orgMembers,
      },
    ],
  },
  {
    name: "Billing",
    items: [
      {
        name: "Subscription",
        link: Routes.orgSubscription,
      },
      {
        name: "Overage",
        link: Routes.orgOverage,
      },
      {
        name: "Receipts",
        link: Routes.orgReceipts,
        notImplemented: true,
      },
      {
        name: "Billing Details",
        link: Routes.orgBillingDetails,
      },
    ],
  },
];

export const userSettingsPageMenuItems: SideBarSubMenuItemProps[] = [
  {
    name: "User",
    items: [
      {
        name: "Security",
        link: Routes.userSecurity,
      },
      {
        name: "Pending Invitations",
        link: Routes.userPendingInvitations,
      },
      {
        name: "Create Organisation",
        link: Routes.userCreateOrganisation,
      },
      {
        name: "Legal",
        link: Routes.legal,
      },
    ],
  },
  {
    name: "Billing",
    items: [
      {
        name: "Subscription",
        link: Routes.userSubscription,
      },
      {
        name: "Overage",
        link: Routes.userOverage,
      },
      {
        name: "Receipts",
        link: Routes.userReceipts,
        notImplemented: true,
      },
      {
        name: "Billing Details",
        link: Routes.userBillingDetails,
      },
    ],
  },
];

export const adminSettingsPageMenuItems: SideBarSubMenuItemProps[] = [
  {
    name: "Dashboard",
    link: Routes.adminDashboard,
  },
  {
    name: "Customer Management",
    items: [
      {
        name: "Accounts",
        link: Routes.adminAccount,
      },
    ],
  },
  {
    name: "Release Management",
    items: [
      {
        name: "Releases",
        link: Routes.adminReleases,
      },
      {
        name: "Datasets",
        link: Routes.adminDatasets,
      },
      {
        name: "Custom Data",
        link: Routes.adminReceipts,
        notImplemented: true,
        disabled: true,
      },
    ],
  },
  {
    name: "Reporting",
    link: Routes.adminCustomerUsage,
    disabled: true,
  },
  {
    name: "Property Insights",
    link: Routes.propertyInsights,
  },
];

export const developerMenuItems: SideBarSubMenuItemProps[] = [
  {
    name: "Dashboard",
    link: Routes.developerDashboard,
    // notImplemented: true,
  },
  {
    name: "API Keys",
    link: Routes.developerApiKeys,
  },
  {
    name: "API Docs",
    link: "https://api-docs.geoscape.com.au/",
    target: "_blank",
  },

  {
    name: "Usage",
    link: Routes.developerUsage,
  },
];

export const dataMenuItems: SideBarSubMenuItemProps[] = [
  {
    name: "Explorer",
    link: Routes.dataExplorer,
  },
  {
    name: "Catalogue",
    link: Routes.dataCatalogue,
  },
  {
    name: "Download",
    link: Routes.dataDownload,
  },
  {
    name: "Custom Download",
    link: Routes.dataCustomDownload,
  },
  {
    name: "Tailor",
    link: Routes.dataTailor,
    notImplemented: true,
    disabled: true,
  },
];
