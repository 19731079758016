import { Box } from "@mui/material";
import { SubscriptionCards } from "components/billing/subscription-cards";
import dayjs from "dayjs";
import { useState } from "react";
import { UsageGraph } from "../usage/components/usage-graph/usage-graph";

export const Dashboard = () => {
  const [startTime, setStartTime] = useState(
    dayjs().subtract(1, "week").unix() * 1000
  );
  const [endTime, setEndTime] = useState(dayjs().unix() * 1000);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        height: "700px",
        position: "relative",
      }}
    >
      <SubscriptionCards />

      <UsageGraph startTime={startTime} endTime={endTime} />
    </Box>
  );
};
