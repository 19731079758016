import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import LocationOn from "@mui/icons-material/LocationOn";
import MonitorIcon from "@mui/icons-material/Monitor";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { CallToActionList } from "components/customer-journey/call-to-action";
import {
  ResendVerification,
  ResetPassword,
  SignIn,
  SignUp,
} from "components/user";
import GeoscapeBackgroundSvg from "icons/background.svg?react";
import GeoscapeDeveloperSvg from "icons/hub-logo-signIn.svg?react";
import { renderToStaticMarkup } from "react-dom/server";
import { Redirect, Route, Switch, useLocation } from "react-router";
import * as Routes from "routes";

export const SignedOut = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  /**
   * App in a signed out state.
   * A simple page layout with a form in center page depending on what route the anonymous user is on.
   */

  const prevLocation = useLocation();
  if (prevLocation.pathname != "/sign-in") {
    localStorage.setItem(`redirectPath`, prevLocation.pathname);
    localStorage.setItem(`query`, prevLocation.search);
  }

  const svgString = encodeURIComponent(
    renderToStaticMarkup(
      <GeoscapeBackgroundSvg fill={isDark ? "white" : "black"} />
    )
  );

  return (
    <>
      <div
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8, ${svgString}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          position: "relative",
        }}
      >
        <Switch>
          <Route path="*">
            <Box
              alignItems="center"
              // minHeight="100%"
              height="100%"
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingTop: "75px",
              }}
              overflow="auto"
            >
              <Container>
                <Container maxWidth="sm">
                  <Box
                    display="flex"
                    justifyContent="center"
                    paddingY={5}
                    paddingX={1}
                    bgcolor={"background.default"}
                  >
                    <GeoscapeDeveloperSvg
                      fill={isDark ? "white" : "black"}
                      scale={0.2}
                    />
                  </Box>
                </Container>
                <Box display="flex">
                  <Box>
                    <Switch>
                      <Route exact path={Routes.signUp}>
                        <CallToActionList
                          callToActions={[
                            {
                              heading: "Access to Open Data",
                              text: "Quarterly updated open data",
                              icon: <MonitorIcon />,
                            },
                            {
                              heading: "Get Started For Free",
                              text: "Use seven and growing different APIs for free with our freemium plan.",
                              icon: <LocationOn />,
                            },
                            {
                              heading: "Trusted spatial data for your business",
                              text: "Geoscape is trusted by government and enterprise alike, check out samples and talk to us about how Geoscape can enable your business.",
                              icon: <AssuredWorkloadIcon />,
                            },
                          ]}
                        />
                      </Route>
                      <Route exact path={Routes.welcomeBack}>
                        <CallToActionList
                          callToActions={[
                            {
                              heading:
                                "Thank you for taking the time to migrate your login",
                              text: "Don’t worry if you have active keys; they will work even if you haven’t migrated your login.",
                              icon: false,
                            },
                            {
                              heading: "",
                              text: "As part of the migration, we're making sure we have the right details for you. Please complete the form on the right, and we will send you an email verification link.",
                              icon: false,
                            },
                            {
                              heading: "",
                              text: "You will then be able to log in to the new Geoscape Hub and manage your plan and keys.",
                              icon: false,
                            },
                          ]}
                        />
                      </Route>
                    </Switch>
                  </Box>
                  <Container maxWidth="sm" style={{ padding: "10px" }}>
                    <Box>
                      <Switch>
                        <Route exact path={Routes.signIn}>
                          <SignIn />
                        </Route>
                        <Route exact path={"/"}>
                          <SignIn />
                        </Route>
                        <Route exact path={Routes.signUp}>
                          <SignUp />
                        </Route>
                        <Route exact path={Routes.resetPassword}>
                          <ResetPassword />
                        </Route>
                        <Route exact path={Routes.resendVerification}>
                          <ResendVerification />
                        </Route>
                        <Route path={Routes.dataCatalogue}>
                          <Redirect to={Routes.signUp} />
                        </Route>
                        <Route path="*">
                          <Redirect to={Routes.signIn} />
                        </Route>
                      </Switch>
                    </Box>
                  </Container>
                </Box>
              </Container>
            </Box>
          </Route>
        </Switch>
        <Box position={"absolute"} bottom={"0px"} sx={{ ml: 2 }}>
          <Typography>
            <sub>v2.0.0.{import.meta.env.VITE_BUILD_NO}</sub>
          </Typography>
        </Box>
      </div>
    </>
  );
};
