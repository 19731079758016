import { Card, CardHeader, Skeleton } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { useIdentity } from "contexts/identity-context";
import { UsageState } from "contexts/models";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as echarts from "echarts";

import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { getActiveOrganisation } from "contexts/identity-context";
import { useEffect, useRef, useState } from "react";
import useDarkMode from "use-dark-mode";
import { darkTheme } from "../../dark";
import { useFetchApiKeys, useFetchUsageGraph } from "../../fetch-hooks";
import {
  EChartsOptions,
  extractEChartData,
  formatter2,
  getDisplayTimeFormat,
  unixDurationToDateRange,
} from "../../utils-usage";

//  TODO , ADD ERROR MESSAGES

interface UsageGraphProps {
  startTime: number;
  endTime: number;
}

interface UsageGraphbyTimeProps {
  startTime: number;
  endTime: number;
  isLoading: boolean;
  isError: boolean;
  isSuccess?: boolean;
  usageQuery?: any;
}

export const UsageGraph = (props: UsageGraphProps) => {
  echarts.registerTheme("darkTheme", darkTheme);
  const darkMode = useDarkMode(false);

  // useQuery
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);

  const duration = unixDurationToDateRange(props.startTime, props.endTime);

  const initialDailyUsageState: UsageState = {
    startTime: props.startTime,
    endTime: props.endTime,
    usage: undefined,
    duration: duration,
  };

  const [usageGraph, setUsageGraph] = useState<UsageState>(
    initialDailyUsageState
  );

  const usageQuery = useFetchUsageGraph(
    isUser,
    identityState,
    props.startTime,
    props.endTime,
    duration,
    setUsageGraph
  );

  const apiKeysQuery = useFetchApiKeys(isUser, identityState);

  const isLoading = usageQuery.isLoading || apiKeysQuery.isLoading;
  const isError = usageQuery.isError || apiKeysQuery.isError;
  const isSuccess = usageQuery.isSuccess && apiKeysQuery.isSuccess;

  const displayTimeformat = getDisplayTimeFormat(usageGraph.duration);

  dayjs.extend(customParseFormat);
  dayjs.extend(advancedFormat);

  const chartRef = useRef(null);

  useEffect(() => {
    if (isSuccess) {
      //@ts-ignore
      let chartInstance = echarts.getInstanceByDom(chartRef.current);

      if (!chartInstance) {
        chartInstance = echarts.init(
          chartRef.current,
          darkMode.value ? darkTheme : undefined
        );
      }

      const option = EChartsOptions(
        extractEChartData(usageGraph, apiKeysQuery, isLoading, isError),
        displayTimeformat,
        usageQuery.data.intervalHours,
        isLoading,
        isError
      );

      chartInstance.setOption({ ...option, notMerge: true });

      // Cleanup on unmount
      return () => {
        chartInstance.dispose();
      };
    }
  }, [isSuccess]);

  return (
    <Card>
      <CardHeader title="Credit Usage" />
      <CardContent>
        {isLoading && (
          <>
            <Skeleton variant="rectangular" width="100%" height="100px" />
            <br />
            <Skeleton variant="rectangular" width="100%" height="300px" />
          </>
        )}

        <ReactQueryErrorWrapper
          queries={[usageQuery, apiKeysQuery]}
          mutations={[]}
        />

        {isSuccess && (
          <>
            <div ref={chartRef} style={{ height: 500 }} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export const UsageGraphbyTime = (props: UsageGraphbyTimeProps) => {
  echarts.registerTheme("darkTheme", darkTheme);
  const darkMode = useDarkMode(false);

  const usageQuery = props.usageQuery;

  dayjs.extend(customParseFormat);
  dayjs.extend(advancedFormat);

  const chartRef = useRef(null);

  useEffect(() => {
    if (props.isSuccess) {
      //@ts-ignore
      let chartInstance = echarts.getInstanceByDom(chartRef.current);

      if (!chartInstance) {
        chartInstance = echarts.init(
          chartRef.current,
          darkMode.value ? darkTheme : undefined
        );
      }

      const showTitle: boolean =
        usageQuery.data.appCreditsData.series === 0 ||
        props.isLoading ||
        props.isError;
      const showData: boolean =
        usageQuery.data.appCreditsData.series.length > 0 &&
        !props.isLoading &&
        !props.isError;
      const titleText: string = props.isLoading
        ? "Loading the usage data"
        : props.isError
          ? "An error occurred while loading data"
          : "No usage data";

      const option = {
        title: {
          show: showTitle,
          textStyle: {
            color: "grey",
            fontSize: 20,
          },
          text: titleText,
          left: "center",
          top: "center",
        },
        tooltip: {
          renderMode: "html",
          appendToBody: true,
          trigger: "axis",
          formatter: (params: any) => {
            return formatter2(params, usageQuery.data.intervalHours);
          },
        },
        legend: {
          type: "scroll",
          orient: "horizontal",
          bottom: 0,
          left: 0,
          data: usageQuery.data.appNames,
        },
        xAxis: {
          show: showData,
          type: "category",
          data: usageQuery.data.appCreditsData.dataTime.map(
            (dateStr: string) => {
              if (
                usageQuery.data.intervalHours === "1d" ||
                usageQuery.data.intervalHours === "1w"
              ) {
                return dayjs(dateStr).format("MMM D");
              } else {
                return dayjs(dateStr).format("MMM YYYY");
              }
            }
          ),
        },
        yAxis: {
          show: showData,
          type: "value",
        },
        series: usageQuery.data.appCreditsData.series,
      };

      chartInstance.setOption({ ...option, notMerge: true });

      // Cleanup on unmount
      return () => {
        chartInstance.dispose();
      };
    }
  }, [props.isSuccess]);

  return (
    <Card>
      <CardHeader title="Credit Usage" />
      <CardContent>
        <ReactQueryErrorWrapper queries={[usageQuery]} mutations={[]} />

        {props.isSuccess && (
          <>
            <div ref={chartRef} style={{ height: 500 }} />
          </>
        )}
      </CardContent>
    </Card>
  );
};
