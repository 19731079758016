import {
  Avatar,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import _ from "lodash";

import { purple, blue } from "@mui/material/colors";
import { GridOverlay } from "@mui/x-data-grid-premium";

const userColor = blue[400];
const orgColor = purple[300];

export function NameEmailAvatarRound(props: {
  name: string;
  email: string;
  isLoading: boolean;
  variant: "circular" | "square" | "rounded" | undefined;
}) {
  if (props.isLoading)
    return (
      <Skeleton
        animation="wave"
        variant={props.variant === "circular" ? "circular" : "rectangular"}
        width={40}
        height={40}
      />
    );

  if (props.name) {
    return (
      <Stack direction="row" spacing={5}>
        <Avatar {...stringAvatar(props.name)} variant={props.variant} />
        <Stack direction="column">
          <Typography variant="body2">{props.name}</Typography>
          <Typography variant="body2">{props.email}</Typography>
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack direction="row" spacing={5}>
        <Avatar variant={props.variant} />
        <Stack direction="column">
          <Typography variant="body2" sx={{ marginTop: "20px" }}>
            {props.email}
          </Typography>
        </Stack>
      </Stack>
    );
  }
}

export function stringAvatar(name: string) {
  name = name.trim();
  let shortName: string = "";

  if (!name) {
    // No value
    shortName = "";
  } else if (name.length === 1) {
    // Single character
    shortName = name[0].toUpperCase();
  } else if (!name.includes(" ")) {
    // Single word
    shortName = `${name[0].toUpperCase()}${name[1].toLowerCase()}`;
  } else {
    // Multiple words
    const withoutExtraWhitespace = name.replace(/ +(?= )/g, "");
    const nameFragments: string[] = withoutExtraWhitespace.split(" "); // Replace any whitespace with single space before splitting
    shortName = `${nameFragments[0][0].toUpperCase()}${nameFragments[1][0].toUpperCase()}`;
  }

  return {
    //   sx: {
    //     bgcolor: stringToColor(name ? name : "empty"),
    //   },
    children: shortName,
  };
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function prepareCase(name: string): string {
  if (_.startCase(_.toLower(name)) === "") {
    return name;
  }
  return _.startCase(_.toLower(name));
}

export function BackgroundLetterAvatarsCircular(props: { name: string }) {
  return (
    <Stack direction="row">
      <Avatar {...stringAvatar(props.name)} variant="circular" />
    </Stack>
  );
}

export function formatAbn(abn: number): string {
  const abnStr: string = abn.toString();
  if (abnStr && abnStr.length === 11)
    return `ABN ${abnStr.substring(0, 2)} ${abnStr.substring(
      2,
      5
    )} ${abnStr.substring(5, 8)} ${abnStr.substring(8, 11)}`;
  return abnStr;
}

interface props {
  name: string;
  activeOrg: any;
  isLoading: boolean;
}
export function BackgroundLetterAvatarsSquare({
  name,
  activeOrg,
  isLoading,
}: props) {
  return (
    <Stack direction="row">
      {name && !isLoading ? (
        <Avatar
          {...stringAvatar(name)}
          variant={activeOrg ? "square" : "circular"}
          sx={
            activeOrg
              ? { bgcolor: orgColor, borderRadius: "5px" }
              : { bgcolor: userColor }
          }
        />
      ) : (
        <Skeleton animation="wave" variant="rounded" width={40} height={40} />
      )}
    </Stack>
  );
}

export const LoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};
