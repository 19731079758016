import { EChartsSeries } from "./models";

export const extractKeys = (seriesData: EChartsSeries[]): string[] => {
    return seriesData.reduce<string[]>((acc, series) => {
        const hasNonZeroData = series.data.reduce((sum, val) => sum + val, 0) !== 0;
        if (series.name.includes("DELETED") && hasNonZeroData) {
            acc.unshift(series.name);  // Add to the beginning if 'DELETED' and has non-zero data
        } else {
            acc.push(series.name);  // Add to the end otherwise
        }
        return acc;
    }, []);
};