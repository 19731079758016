import { DateRange } from "contexts/models";
import {
  DateRange as MuiDateRange,
  DefinedRange as MuiDefinedRange,
} from "mui-daterange-picker";

import { BaseState } from "common/models";
import dayjs, { Dayjs } from "dayjs";
import { extractKeys } from "./components/usage-graph/utils";

interface EChartsSeries {
  name: string;
  data: number[];
}

interface EChartData extends BaseState {
  timeBucketLabels: Dayjs[];
  series: EChartsSeries[];
}

interface EChartsSeries {
  name: string;
  data: number[];
}

interface EChartData extends BaseState {
  timeBucketLabels: Dayjs[];
  series: EChartsSeries[];
}

export const timePeriodFromDateRange = (
  dateRange: DateRange
): [number, number] => {
  const endTime = dayjs().unix();
  var startTime = 0;
  switch (dateRange) {
    case DateRange.DAY: {
      startTime = dayjs().subtract(24, "hours").unix();
      break;
    }
    case DateRange.WEEK: {
      startTime = dayjs().subtract(7, "days").unix();
      break;
    }
    case DateRange.TWO_WEEKS: {
      startTime = dayjs().subtract(14, "days").unix();
      break;
    }
    case DateRange.MONTH: {
      startTime = dayjs().subtract(30, "days").unix();
      break;
    }
    case DateRange.THREE_MONTHS: {
      startTime = dayjs().subtract(90, "days").unix();
      break;
    }
  }
  return [startTime * 1000, endTime * 1000];
};

export const extractEChartData = (
  usageGraph: any,
  apiKeyQuery: any,
  isLoading: boolean,
  isError: boolean
) => {
  let keyNameMapping: Record<string, string> = {};

  apiKeyQuery.data.forEach(function (key: any, index: any) {
    const keyName: string = key.name;
    const keyDisplayName: string = key.displayName ? key.displayName : key.name;
    keyNameMapping[keyName] = keyDisplayName;
  });

  var longest_time_series: Dayjs[] = [];

  usageGraph.usage.appGraphData.forEach((app: any) => {
    if (app.buckets.length > longest_time_series.length) {
      longest_time_series = app.buckets.map((x: any) => {
        var p_time = dayjs(x.timestamp);

        return p_time;
      });
    }
  });

  var seriesData: EChartsSeries[] = usageGraph.usage.appGraphData.map(
    (app: any) => {
      return {
        data: app.buckets.map((x: any) => {
          return Number(x.value.toFixed(1));
        }),
        name: resolveAppName(app.app, keyNameMapping),
      };
    }
  );

  const data: EChartData = {
    isLoading: isLoading,
    isError: isError,
    message: usageGraph.isError ? "Unable to load data" : "",
    timeBucketLabels: longest_time_series,
    series: seriesData,
  };

  return data;
};

export const formatter = (
  params: any,
  displayTimeformat: string,
  intervalHours: number
) => {
  const tooltipHeader =
    params[0].axisValue +
    " - " +
    dayjs(params[0].axisValue, displayTimeformat)
      .add(intervalHours, "hour")
      .format(displayTimeformat.replace("ddd", ""));

  const tooltipList = params
    .filter((param: any) => param.value)
    .map((param: any, index: number) => {
      const html = `<div style="display:flex;">
    <div id="rectangle" style="border-radius: 20px; width:20px; height:20px; background-color:${param.color}"></div>
    <div style="margin-left: 10px;">${param.seriesName}: ${param.value}</div></div>`;
      return html;
    });

  if (tooltipList.length > 0) {
    return tooltipHeader + "<hr/>" + tooltipList.join("");
  }
  return tooltipHeader;
};

export const formatter2 = (params: any, intervalHours: string) => {
  var tooltipHeader;
  if (intervalHours === "1d") {
    tooltipHeader = params[0].axisValue;
  }
  if (intervalHours === "1w") {
    tooltipHeader =
      params[0].axisValue +
      " - " +
      dayjs(params[0].axisValue).add(6, "day").format("D MMM");
  } else tooltipHeader = params[0].axisValue;

  const tooltipList = params
    .filter((param: any) => param.value)
    .map((param: any, index: number) => {
      const html = `<div style="display:flex;">
    <div id="rectangle" style="border-radius: 20px; width:20px; height:20px; background-color:${param.color}"></div>
    <div style="margin-left: 10px;">${param.seriesName}: ${param.value}</div></div>`;
      return html;
    });

  if (tooltipList.length > 0) {
    return tooltipHeader + "<hr/>" + tooltipList.join("");
  }
  return tooltipHeader;
};

export const EChartsOptions = (
  chartData: EChartData,
  displayTimeformat: string,
  intervalHours: number,
  isLoading: boolean,
  isError: boolean
): any => {
  const showTitle: boolean =
    chartData.series.length === 0 || isLoading || isError;
  const showData: boolean =
    chartData.series.length > 0 && !isLoading && !isError;
  const titleText: string = isLoading
    ? "Loading the usage data"
    : isError
    ? "An error occurred while loading data"
    : "No usage data";

  const deleted_keys = chartData.series
    .filter(
      (x) =>
        x.name.includes("DELETED") &&
        x.data.reduce((partialSum, a) => partialSum + a, 0) !== 0
    )
    .map((n) => n.name);

  const keys_in_use = chartData.series
    .filter((x) => !x.name.includes("DELETED"))
    .map((n) => n.name);

  const keys = extractKeys(chartData.series);

  return {
    title: {
      show: showTitle,
      textStyle: {
        color: "grey",
        fontSize: 20,
      },
      text: titleText,
      left: "center",
      top: "center",
    },
    tooltip: {
      renderMode: "html",
      appendToBody: true,
      trigger: "axis",
      formatter: (params: any) => {
        return formatter(params, displayTimeformat, intervalHours);
      },
    },
    legend: {
      type: "scroll",
      orient: "horizontal",
      bottom: 0,
      left: 0,
      // data: chartData.series.map((x) => x.name)
      data: keys,
    },
    grid: {
      left: 10,
      right: 10,
      bottom: 50,
      top: 10,
      containLabel: true,
    },
    xAxis: {
      show: showData,
      data: chartData.timeBucketLabels.map((day) =>
        day.format(displayTimeformat)
      ),
    },
    yAxis: {
      show: showData,
    },
    series: chartData.series.map((x) => {
      return {
        name: x.name,
        data: x.data,
        type: "bar",
        stack: "usage",
      };
    }),
  };
};

export const getDisplayTimeFormat = (dateRange: DateRange): string => {
  switch (dateRange) {
    case DateRange.DAY: {
      return "ddd hh:mm A";
    }
    case DateRange.WEEK: {
      return "ddd hh:mm A";
    }
    case DateRange.TWO_WEEKS: {
      return "MMM DD hh:mm A";
    }
    case DateRange.MONTH: {
      return "MMM DD hh:mm A";
    }
    case DateRange.THREE_MONTHS: {
      return "MMM DD";
    }
  }
};

export const durationToDateRange = (duration: MuiDateRange): DateRange => {
  if (!duration.endDate || !duration.startDate) {
    return DateRange.DAY;
  }
  return unixDurationToDateRange(
    duration.startDate.getTime(),
    duration.endDate.getTime()
  );
};

export const unixDurationToDateRange = (
  startTime: number,
  endTime: number
): DateRange => {
  const one_hour = 1000 * 60 * 60;
  const one_day = one_hour * 24;
  const one_week = one_day * 7;
  var delta = endTime - startTime;

  if (delta <= one_day) {
    return DateRange.DAY;
  }
  if (delta <= one_week) {
    return DateRange.WEEK;
  }
  if (delta <= one_week * 2) {
    return DateRange.TWO_WEEKS;
  }
  if (delta <= one_day * 30) {
    return DateRange.MONTH;
  }

  return DateRange.THREE_MONTHS;
};

export const preparePreDefinedRanges = (): MuiDefinedRange[] => {
  let preDefinedRanges: MuiDefinedRange[] = [];
  for (let item in DateRange) {
    const startEnd = timePeriodFromDateRange(
      DateRange[item as keyof typeof DateRange]
    );
    preDefinedRanges.push({
      startDate: new Date(startEnd[0]),
      endDate: new Date(startEnd[1]),
      label: DateRange[item as keyof typeof DateRange],
    });
  }
  return preDefinedRanges;
};

export const resolveAppName = (
  appName: string,
  keyNameMapping: Record<string, string>
): string => {
  let keyName;
  if (appName === "") {
    keyName = "Service (No Key)";
  } else if (keyNameMapping[appName]) {
    keyName = keyNameMapping[appName];
  } else {
    keyName = `${appName} (Deleted)`;
  }
  return keyName;
};
