import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Link,
  TextField,
} from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { BaseState } from "common/models";
import { signUpUser } from "contexts/identity-context";
import { Form, Formik } from "formik";
import { replace } from "lodash/fp";
import React, { createRef, Dispatch, SetStateAction, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Routes from "routes";
import { boolean as bool, object, ref, string } from "yup";

export const signUpValidationSchema = object({
  email: string().email("Enter a valid email").required("Email is required"),
  firstName: string().required("First Name is required."),
  lastName: string().required("Last Name is required."),
  password: string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required."),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Confirm Password is required."),
  termsOfUse: bool().oneOf(
    [true],
    "In order to continue, you must accept Geoscape Hub's Terms of Use."
  ),
});

type SignUpValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
};

interface SignUpState extends BaseState {
  messageSeverity?: AlertColor;
}

function trimmessage(message: string): string {
  return replace(
    "..",
    ".",
    replace("PreSignUp failed with error ", "", message)
  );
}

async function signUp(
  event: SignUpValues,
  recaptchaToken: string,
  setState: Dispatch<SetStateAction<SignUpState>>
) {
  if (!recaptchaToken) {
    setState((prevState) => {
      return {
        ...prevState,
        isError: true,
        message: "Please complete the reCaptcha challenge.",
        messageSeverity: "error",
      };
    });
    return;
  }
  setState((prevState) => {
    return {
      ...prevState,
      isLoading: true,
    };
  });
  try {
    const confirm = await signUpUser(
      event.email,
      event.firstName,
      event.lastName,
      event.password,
      recaptchaToken
    );
    switch (confirm.state) {
      case "left": {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            message: `A verification email has been sent to: ${event.email}`,
            messageSeverity: "success",
          };
        });
        break;
      }
      case "right": {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            message: trimmessage(confirm.value.message),
            messageSeverity: "error",
          };
        });
        break;
      }
    }
  } finally {
    // just make sure that we've unlocked the page at the very least by setting isLoading to false
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: false,
      };
    });
  }
}

export const SignUp = () => {
  const initialState = {
    isLoading: false,
    isError: false,
    message: "",
  };
  const [state, setState] = useState<SignUpState>(initialState);
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");

  const recaptchaRef = createRef<ReCAPTCHA>();
  const recaptchaKey: string = import.meta.env.VITE_RECAPTCHA_KEY || "EMPTY";

  const handleVerify = () => {
    const token = recaptchaRef?.current?.getValue();
    setRecaptchaToken(token || "");
    recaptchaRef?.current?.reset();
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik<SignUpValues>
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          termsOfUse: false,
        }}
        onSubmit={async (e) => {
          signUp(e, recaptchaToken, setState);
        }}
        validationSchema={signUpValidationSchema}
      >
        {({
          values: {
            email,
            firstName,
            lastName,
            password,
            confirmPassword,
            termsOfUse,
          },
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form style={{ paddingBottom: "50px" }}>
            <Card
              style={{
                padding: "16px",
                paddingBottom: "0px",
                position: "relative",
                top: "-35px",
              }}
            >
              <TextField
                id="email"
                name="email"
                label="Email"
                type="text"
                margin="normal"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                disabled={state.isLoading}
                // variant="filled"
                fullWidth
              />
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                type="text"
                margin="normal"
                value={firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                disabled={state.isLoading}
                // variant="filled"
                fullWidth
              />
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                type="text"
                margin="normal"
                value={lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                disabled={state.isLoading}
                // variant="filled"
                fullWidth
              />
              <TextField
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                margin="normal"
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                disabled={state.isLoading}
                // variant="filled"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                margin="normal"
                value={confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                disabled={state.isLoading}
                // variant="filled"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    id="termsOfUse"
                    name="termsOfUse"
                    checked={termsOfUse}
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    {"I agree to Geoscape Hub's "}
                    <a
                      href="https://geoscape.com.au/legal/geoscape-developer-terms"
                      target="_blank"
                      rel="noreferrer"
                      // style={{ textDecoration: "none" }}
                    >
                      Terms of Use
                    </a>
                  </>
                }
              />
              <FormHelperText error={true}>
                {errors["termsOfUse"]}
              </FormHelperText>
              <Box
                marginY={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ReCAPTCHA
                  id="recaptcha"
                  ref={recaptchaRef}
                  sitekey={recaptchaKey}
                  onChange={handleVerify}
                />
                {state.isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Box
                      hidden={
                        !Boolean(state.message) &&
                        !Boolean(state.messageSeverity)
                      }
                      marginBottom={2}
                      marginTop={2}
                      style={{ width: "100%" }}
                    >
                      <Alert
                        variant="outlined"
                        severity={state.messageSeverity}
                      >
                        {state.message}
                      </Alert>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "16px",
                      }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={state.isLoading}
                        fullWidth
                        children="Sign Up"
                      />

                      <Divider />

                      <Button
                        LinkComponent={Link}
                        disabled={state.isLoading}
                        size="small"
                        fullWidth
                        href={Routes.signIn}
                      >
                        Sign In
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};
