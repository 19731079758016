
import { CustomDatasetDefinitions } from "data/models";
import { useState } from "react";
import { FileWithPath } from "react-dropzone";
import { UseQueryResult } from "react-query";
import { UseCustomDatasetRowsResult } from "./types";
import { GridRowModel } from "@mui/x-data-grid-premium";




export function useCustomDatasetRows(
  customDatasetsDefinitionsQuery: UseQueryResult<CustomDatasetDefinitions, any>
): UseCustomDatasetRowsResult {
  const [rows, setRows] = useState<GridRowModel[]>([]);

  const addRows = (files: FileWithPath[]) => {
    if (!customDatasetsDefinitionsQuery.isSuccess) {
      return;
    }
    const extractVersion = (filePath: string) => {
      const maybeVersion = +filePath.split(".")[0].split("_").slice(-1)[0];
      return maybeVersion ? maybeVersion : "";
    };
    setRows((prevState) => {
      return [
        ...prevState,
        ...files.map((file: FileWithPath) => {
          return {
            id: file.path,
            fileName: file.path,
            name: customDatasetsDefinitionsQuery.data.names
              ? customDatasetsDefinitionsQuery.data.names[0]
              : "",
            region: customDatasetsDefinitionsQuery.data.regions
              ? customDatasetsDefinitionsQuery.data.regions[0]
              : "",
            format: customDatasetsDefinitionsQuery.data.formats
              ? customDatasetsDefinitionsQuery.data.formats[0]
              : "",
            datum: customDatasetsDefinitionsQuery.data.datums
              ? customDatasetsDefinitionsQuery.data.datums[0]
              : "",
            release: customDatasetsDefinitionsQuery.data.releases
              ? customDatasetsDefinitionsQuery.data.releases[0]
              : "",
            fileSize: file.size,
            version: file.path ? extractVersion(file.path) : "",
            errors: [],
          };
        }),
      ];
    });
  };

  const editRow = (id: string, field: string, value: string) => {
    setRows((prevState) => {
      const newState = prevState.map((row: GridRowModel) => {
        if (row.id === id) {
          const newVals = {
            ...row,
            [field]: value,
          };
          return newVals;
        }
        return row;
      }) as any;
      return newState;
    });
  };

  const removeRow = (id: string) => {
    setRows((prevState) => {
      return prevState.filter((x) => x.id !== id);
    });
  };
  return {
    rows,
    addRows,
    editRow,
    removeRow,
  };
}
