import * as QueryKeys from "data";
import { useIntercom } from "react-use-intercom";

import { Box, Card, CardContent, CardHeader, FormGroup } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";

import { makeStyles } from "@mui/styles";
import CustomTable from "components/shared/table";
import { fetchCustomDatasets, fetchCustomDefinitions } from "data/queries";
import { useEffect, useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { customColumns, customersToRows } from "./columns-rows";


import { CustomDatasetFilterType, CustomDatasetList } from "data/models";
import FilterComponent from "../share-component/filter-component";
import { updateQueryAndFilters } from "../utils";
import { CustomDatasetFilters } from "./filters";
import * as localStorageKeys from "data/local-storage-keys";
import { GridRowClassNameParams } from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

export const CustomDownloadPage = () => {
  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const savedSearch = localStorage.getItem(
    localStorageKeys.searchCustomDatasets
  );

  let initialQueryFilterState: CustomDatasetFilterType = {
    name: [],
    query: "",
    product: [],
    release: "",
    latestRelease: false,
  };

  const [queryFilterState, setQueryFilterState] =
    useState<CustomDatasetFilterType>(initialQueryFilterState);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const customDatasetsSearchQuery: UseQueryResult<CustomDatasetList> = useQuery(
    [QueryKeys.customDatasetsSearchQuery, identityId, queryFilterState],
    () => fetchCustomDatasets(queryFilterState, identityState)
  );

  const definitions = useQuery([QueryKeys.customDatasetDefinitions], () =>
    fetchCustomDefinitions(identityState)
  );

  useEffect(() => {
    updateQueryAndFilters({
      savedSearch: savedSearch,
      setQueryFilterState,
      setLoadingFilters,
      initialQueryFilterState,
      definitions: definitions,
    });
  }, []);

  const useStyles = makeStyles({
    highlightedRow: {
      color: "grey",
    },
  });

  const classes = useStyles();

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    if (params.row.licenced == false) {
      return classes.highlightedRow;
    }
    return "";
  };

  const customColumns_ = customColumns(identityState);

  const isSuccess = customDatasetsSearchQuery.isSuccess;
  const isLoading = customDatasetsSearchQuery.isLoading;
  const rawsData = customDatasetsSearchQuery.data
    ? customersToRows(customDatasetsSearchQuery.data)
    : [];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "24px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[customDatasetsSearchQuery, definitions]}
          mutations={[]}
        />

        <Card>
          <CardHeader
            title="Search My Custom Datasets"
            style={{ padding: "5" }}
            variant="h5"
          />

          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "10px",
              gap: "20px",
            }}
          >
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              {loadingFilters ? (
                <Box
                  style={{
                    display: "flex",
                    padding: "0px",
                    gap: "10px",
                  }}
                >
                  <h2>Loading...</h2>
                </Box>
              ) : (
                <FilterComponent
                  queryFilterState={queryFilterState}
                  setQueryFilterState={setQueryFilterState}
                  initialQueryFilterState={initialQueryFilterState}
                  renderFilter={CustomDatasetFilters}
                  definitions={definitions}
                  localStorageKey={localStorageKeys.searchCustomDatasets}
                />
              )}
            </FormGroup>
          </CardContent>
        </Card>
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CustomTable
            getCustomRowClass={getCustomRowLicence}
            rows={rawsData}
            cols={customColumns_}
            isLoading={isLoading}
            overlayText={
              "We couldn't find any datasets that match your filter criteria."
            }
          />
        </Card>
      </div>
    </>
  );
};
