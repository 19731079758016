import { Layer } from "react-map-gl";
import { useEffect, useState } from "react";
import { LayerProps } from "../explorer-display-layers";
import { useTheme } from "@mui/material";

export function BorderHighlightLayer(props: LayerProps) {
  const [beforeId, setBeforeId] = useState(props.beforeId ?? "");
  const theme = useTheme();

  useEffect(() => {
    // Check if the referenced layer has been rendered
    const referencedLayer = document.getElementById(beforeId);

    if (!referencedLayer) {
      // If the referenced layer hasn't been rendered yet, update the beforeId in the state
      setBeforeId(props.beforeId ?? "");
    }
  }, [props.beforeId]);

  return (
    <>
      {props.layerConfig.styles.type == "Polygon" && (
        <Layer
          key={props.id}
          layout={{
            visibility: props.layerConfig.visible ? "visible" : "none",
          }}
          beforeId={beforeId}
          id={props.id}
          type="line"
          source={props.source.description}
          source-layer={props.layer.id}
          paint={{
            "line-color": props.layerConfig.styles.colour,
            "line-width": props.layerConfig.styles.thickness,
          }}
        />
      )}
    </>
  );
}
