import {
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRange,
  DateRangeValidationError,
} from "@mui/x-date-pickers-pro/models";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { useFetchSubscription } from "components/usage/fetch-hooks";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { baseShortcutsItems, shortcutsItemsPro } from "./utils";

interface DateRangePickerProps {
  onChange: (
    value: DateRange<Dayjs>,
    context: PickerChangeHandlerContext<DateRangeValidationError>
  ) => void;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const subscriptionQuery = useFetchSubscription(isUser, identityState);

  const isFreemium: boolean =
    subscriptionQuery.isSuccess &&
    subscriptionQuery.data.activeSubscription.plan.name === "Freemium";

  const today = dayjs();
  const thirtyDaysAgo = today.subtract(30, "day");
  const firstOfApril = dayjs().month(3).date(1);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        slotProps={{
          shortcuts: {
            items: shortCutsItems(isFreemium),
          },
          actionBar: { actions: [] },
        }}
        calendars={2}
        onChange={props.onChange}
        // minDate={isFreemium ? thirtyDaysAgo : undefined}
        minDate={firstOfApril}
        maxDate={today}
      />
    </LocalizationProvider>
  );
};

dayjs.extend(quarterOfYear);

// Refactored function to return the correct shortcuts based on the subscription status
const shortCutsItems = (
  isFreemium: boolean
): PickersShortcutsItem<DateRange<dayjs.Dayjs>>[] => {
  return isFreemium ? baseShortcutsItems : shortcutsItemsPro;
};
