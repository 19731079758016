import { useTheme } from "@mui/material";
import { Ref, useState } from "react";
import Map, {
  LngLatBoundsLike,
  MapLayerMouseEvent,
  MapRef,
} from "react-map-gl";

interface MapboxProps {
  longitude?: number;
  latitude?: number;
  bbox?: LngLatBoundsLike;
  bboxPadding?: number;
  zoom?: number;
  onZoom: (zoom: number) => void;
  style: React.CSSProperties;
  children?: React.ReactNode;
  transformRequest?: (url: string, resource: string) => any;
  mapRef: Ref<MapRef>;
  onClick?: (event: MapLayerMouseEvent) => void;
  interactiveLayerIds?: string[];
  attributionControl: boolean;
  minZoom?: number;
  maxZoom?: number;
  interactive?: boolean;
  mapStyle?: string;
}

/**
 * Examples of using Native Mapbox Layers: https://visgl.github.io/react-map-gl/docs/get-started/adding-custom-data#native-mapbox-layers
 * Examples of using Custom Overlays: https://visgl.github.io/react-map-gl/docs/get-started/adding-custom-data#custom-overlays
 * Other Examples: https://visgl.github.io/react-map-gl/examples
 * @param props
 * @returns
 */
export const Basemap = (props: MapboxProps) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  // If lat/lon/zoom are passed it will initialise the map to there
  const [viewState, setViewState] = useState(
    props.latitude && props.longitude && props.zoom
      ? {
          longitude: props.longitude,
          latitude: props.latitude,
          zoom: props.zoom,
        }
      : {}
  );

  // Otherwise if bbox is passed we will initialise to the bbox
  const initialViewState = props.bbox
    ? {
        bounds: props.bbox,
        fitBoundsOptions: { padding: props.bboxPadding ?? 20 },
      }
    : undefined;

  return (
    <Map
      {...viewState}
      initialViewState={initialViewState}
      interactive={props.interactive === undefined ? true : false}
      interactiveLayerIds={props.interactiveLayerIds}
      onMove={(evt) => setViewState(evt.viewState)}
      onZoom={(e) => props.onZoom(e.viewState.zoom)}
      ref={props.mapRef}
      onClick={props.onClick}
      mapboxAccessToken={`${import.meta.env.VITE_MAPBOXGL_TOKEN}`}
      mapLib={import("mapbox-gl")}
      transformRequest={props.transformRequest}
      style={props.style}
      styleDiffing
      mapStyle={
        props.mapStyle
          ? props.mapStyle
          : isDark
          ? "mapbox://styles/geoscape-psma/clpt56v2100ey01r84bav7gn1"
          : "mapbox://styles/geoscape-psma/clpt55jcl00f201px05cd3bf1"
      }
      attributionControl={props.attributionControl}
      minZoom={props.minZoom ? props.minZoom : 0}
      maxZoom={props.maxZoom ? props.maxZoom : 24}
      onRender={(event) => event.target.resize()}
    >
      {props.children}
    </Map>
  );
};
