import * as QueryKeys from "data";

import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
} from "@mui/material";
import { BaseState } from "common/models";
import { DashboardCard } from "components/dashboard/dashboard-card";
import ConfirmationModal from "components/modals/confirmation-modal";
import { darkTheme } from "components/usage/dark";
import {
  durationToDateRange,
  getDisplayTimeFormat,
} from "components/usage/utils-usage";
import {
  ReactQueryQueryError,
  fetchBillingUsage,
  fetchPaymentMethod,
  fetchSubscription,
  fetchUsage,
} from "data/queries";
import dayjs, { Dayjs } from "dayjs";
import { DateRange as MuiDateRange } from "mui-daterange-picker";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useIntercom } from "react-use-intercom";
import useDarkMode from "use-dark-mode";
import { resolveIdentityId } from "utils/identity";
import OverageSummary from "../overage/overageSummary";

import { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import * as Routes from "routes";

import { formatInterval } from "utils/format";
import { formatTimestamp } from "utils/format-date";

import { useSnackbar } from "notistack";

import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { useIdentity } from "contexts/identity-context";
import { cancelFutureSubscription } from "data/mutations";
import * as echarts from "echarts";
import {
  buildDashboardCardPaymentMethod,
  buildDashboardCardRenewal,
  buildDashboardCardSubscription,
  buildDashboardCardUsage,
} from "../../../dashboard/utils-card";

import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
interface EChartsSeries {
  name: string;
  data: number[];
}

interface EChartData extends BaseState {
  timeBucketLabels: Dayjs[];
  series: EChartsSeries[];
}

const EChartsOptions = (
  chartData: EChartData,
  displayTimeformat: string,
  intervalHours: number,
  isLoading: boolean,
  isError: boolean,
  creditAmount?: number
): any => {
  const showTitle: boolean =
    chartData.series[0]?.data.length === 0 || isLoading || isError;
  const showData: boolean =
    chartData.series[0]?.data.length > 0 && !isLoading && !isError;
  const titleText: string = isLoading
    ? "Loading the usage data"
    : isError
    ? "An error occurred while loading data"
    : "No usage data";

  return {
    title: {
      show: showTitle,
      textStyle: {
        color: "grey",
        fontSize: 20,
      },
      text: titleText,
      left: "center",
      top: "center",
    },
    tooltip: {
      renderMode: "html",
      appendToBody: true,
      trigger: "axis",
    },
    grid: {
      containLabel: true,
      top: 10,
      bottom: 10,
      left: 10,
      right: 10,
    },
    xAxis: {
      show: showData,
      data: chartData.timeBucketLabels.map((day) =>
        day.format(displayTimeformat)
      ),
    },
    yAxis: {
      show: showData,
      max: creditAmount ? Math.floor(creditAmount * 1.1) : undefined,
    },
    series: chartData.series.map((x) => {
      return {
        name: x.name,
        data: x.data,
        type: "bar",
        stack: "usage",
        markLine: creditAmount
          ? {
              name: "credit limit",
              label: {
                show: true,
                position: "middle",
              },
              data: [{ yAxis: creditAmount }],
            }
          : undefined,
      };
    }),
  };
};

const extractEChartData = (dailyUsageQuery: any) => {
  var longest_time_series: Dayjs[] = [];

  dailyUsageQuery.data.appGraphData.forEach((app: any) => {
    if (app.buckets.length > longest_time_series.length) {
      longest_time_series = app.buckets.map((x: any) => {
        var p_time = dayjs(x.timestamp);
        return p_time;
      });
    }
  });

  var seriesData: EChartsSeries[] = [];
  const dataArray: number[] = new Array(longest_time_series.length).fill(0);

  dailyUsageQuery.data.appGraphData.forEach((app: any) => {
    app.buckets.forEach((x: any, i: any) => {
      dataArray[i] = dataArray[i] + Number(x.value.toFixed(1));
    });
  });

  var baseUsage: number = 0;
  seriesData.push({
    data: dataArray.map((x) => {
      baseUsage = baseUsage + x;
      return x === 0 ? x : baseUsage;
    }),
    name: "usage",
  });

  const data: EChartData = {
    isLoading: dailyUsageQuery.isLoading,
    isError: dailyUsageQuery.isError,
    message: dailyUsageQuery.isError
      ? (dailyUsageQuery.error as ReactQueryQueryError).message
      : "",
    timeBucketLabels: longest_time_series,
    series: seriesData,
  };

  return data;
};

export const GenericSubscription = (props: { isUserPage: boolean }) => {
  const history = useHistory();
  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState, props.isUserPage);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const darkMode = useDarkMode(false);

  // Queries and Mutations
  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(props.isUserPage, identityState)
  );
  const billingUsageQuery = useQuery([QueryKeys.billingUsage, identityId], () =>
    fetchBillingUsage(props.isUserPage, identityState)
  );
  const paymentMethodQuery = useQuery(
    [QueryKeys.paymentMethod, identityId],
    () => fetchPaymentMethod(props.isUserPage, identityState)
  );

  const queryClient = useQueryClient();
  const futureSubscriptionMutation = useMutation(
    () => cancelFutureSubscription(identityState),
    {
      onSuccess: () => {
        queryClient.setQueryData([QueryKeys.subscription, identityId], {
          ...subscriptionQuery.data,
          activeSubscription: {
            ...subscriptionQuery.data?.activeSubscription,
            nextSub: undefined,
          },
        });
        setCancelFutureSubscriptionModalIsOpen(false);
        enqueueSnackbar(
          "You successfully cancelled your pending subscription downgrade.",
          {
            variant: "success",
          }
        );
      },
    }
  );

  const dailyUsageStartTime = subscriptionQuery.isSuccess
    ? subscriptionQuery.data.activeSubscription.currentPeriodStart
    : 1;
  const dailyUsageEndTime = subscriptionQuery.isSuccess
    ? subscriptionQuery.data.activeSubscription.renewalDate
    : 1;
  const dailyUsageQuery = useQuery(
    [QueryKeys.dailyUsage, identityId],
    () =>
      fetchUsage(
        props.isUserPage,
        identityState,
        dailyUsageStartTime * 1000,
        dailyUsageEndTime * 1000
      ),
    { enabled: !!subscriptionQuery.data }
  );

  const isLoading: boolean =
    subscriptionQuery.isLoading ||
    billingUsageQuery.isLoading ||
    paymentMethodQuery.isLoading;

  const isEnterprise: boolean =
    subscriptionQuery.isSuccess &&
    subscriptionQuery.data.activeSubscription.plan.isEnterprise;

  const isPaymentMyob =
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.isMyob);

  const isSuccess: boolean =
    subscriptionQuery.isSuccess &&
    billingUsageQuery.isSuccess &&
    paymentMethodQuery.isSuccess &&
    dailyUsageQuery.isSuccess;

  const { show } = useIntercom();

  const creditAmount: number = billingUsageQuery.data
    ? billingUsageQuery.data.allowance
    : 0;

  const dashboardCardRenewal = buildDashboardCardRenewal(
    subscriptionQuery,
    isLoading,
    history
  );

  const dashboardCardPaymentMethod = buildDashboardCardPaymentMethod(
    paymentMethodQuery,
    props.isUserPage,
    isLoading,
    isEnterprise,
    isPaymentMyob,
    history
  );

  const dashboardCardUsage = buildDashboardCardUsage(
    billingUsageQuery,
    creditAmount,
    isLoading,
    history
  );

  const dashboardCardSubscription = buildDashboardCardSubscription(
    subscriptionQuery,
    history,
    isLoading,
    props.isUserPage,
    isEnterprise,
    isPaymentMyob
  );

  const hasScheduledDowngrade = subscriptionQuery.data
    ? subscriptionQuery.data.activeSubscription.nextSub != null
    : true;

  const [
    cancelFutureSubscriptionModalIsOpen,
    setCancelFutureSubscriptionModalIsOpen,
  ] = useState(false);

  const { enqueueSupportSnackbar } = useCustomSnackbars();
  const chartRef = useRef(null);
  echarts.registerTheme("darkTheme", darkTheme);

  useEffect(() => {
    if (isSuccess && subscriptionQuery.isSuccess) {
      //@ts-ignore
      let chartInstance = echarts.getInstanceByDom(chartRef.current);

      if (!chartInstance) {
        chartInstance = echarts.init(
          chartRef.current,
          darkMode.value ? darkTheme : undefined
        );
      }

      const option = EChartsOptions(
        extractEChartData(dailyUsageQuery),
        getDisplayTimeFormat(
          durationToDateRange({
            startDate: new Date(
              subscriptionQuery.data.activeSubscription.currentPeriodStart *
                1000
            ),
            endDate: new Date(
              subscriptionQuery.data.activeSubscription.renewalDate * 1000
            ),
          } as MuiDateRange)
        ),
        dailyUsageQuery.data.intervalHours,
        isLoading,
        dailyUsageQuery.isError,
        creditAmount
      );

      chartInstance.setOption({ ...option, notMerge: true });

      // Cleanup on unmount
      return () => {
        chartInstance.dispose();
      };
    }
  }, [isSuccess]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          position: "relative",
          top: "-70px",
          gap: "16px",
        }}
      >
        <Button
          onClick={() => {
            if (isEnterprise || isPaymentMyob) {
              enqueueSupportSnackbar(
                "To manage your subscription, please contact support."
              );
            } else {
              history.push(
                props.isUserPage
                  ? Routes.userManageSubscription
                  : Routes.orgManageSubscription
              );
            }
          }}
          variant="contained"
          disabled={hasScheduledDowngrade || subscriptionQuery.isLoading}
          size="large"
          startIcon={<EditIcon />}
        >
          Manage Subscription
        </Button>
      </Box>
      {subscriptionQuery.isSuccess &&
        subscriptionQuery.data.activeSubscription.nextSub &&
        isSuccess && (
          <>
            <Alert
              severity="info"
              action={
                <Button
                  size="small"
                  onClick={() => {
                    futureSubscriptionMutation.reset();
                    setCancelFutureSubscriptionModalIsOpen(true);
                  }}
                >
                  Cancel scheduled change
                </Button>
              }
            >
              <AlertTitle>Scheduled downgrade</AlertTitle>
              You have a scheduled downgrade from{" "}
              {subscriptionQuery.data.activeSubscription.plan.name}{" "}
              {formatInterval(
                subscriptionQuery.data.activeSubscription.plan.interval
              )}{" "}
              to {subscriptionQuery.data.activeSubscription.nextSub.plan.name}{" "}
              {formatInterval(
                subscriptionQuery.data.activeSubscription.nextSub.plan.interval
              )}{" "}
              that will take effect on the{" "}
              {formatTimestamp(
                subscriptionQuery.data.activeSubscription.nextSub.startTime *
                  1000
              )}
              . Until then you are still able to use all of the features of{" "}
              {subscriptionQuery.data.activeSubscription.plan.name}.
            </Alert>

            <ConfirmationModal
              isLoading={futureSubscriptionMutation.isLoading}
              isError={futureSubscriptionMutation.isError}
              open={cancelFutureSubscriptionModalIsOpen}
              header={`Confirm Cancellation of Downgrade`}
              body={`Are you sure you want to cancel the scheduled downgrade? When cancelled, your previous subscription of ${
                subscriptionQuery.data.activeSubscription.plan.name
              } ${formatInterval(
                subscriptionQuery.data.activeSubscription.plan.interval
              )} will renew on ${formatTimestamp(
                subscriptionQuery.data.activeSubscription.renewalDate * 1000
              )}.`}
              leftButtonText={"Confirm"}
              leftButtonColor="primary"
              rightButtonText={"Cancel"}
              rightButtonColor="warning"
              handleLeftButton={() => futureSubscriptionMutation.mutate()}
              handleRightButton={() => {
                setCancelFutureSubscriptionModalIsOpen(false);
              }}
              handleClose={() => setCancelFutureSubscriptionModalIsOpen(false)}
            />
          </>
        )}

      <ReactQueryErrorWrapper
        queries={[
          subscriptionQuery,
          billingUsageQuery,
          paymentMethodQuery,
          dailyUsageQuery,
        ]}
        mutations={[futureSubscriptionMutation]}
      />

      {/* Cards */}
      <Box
        style={{
          display: "flex",
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "0",
          flexWrap: "wrap",
        }}
      >
        <DashboardCard
          cardState={dashboardCardRenewal}
          disableActionBtn={true}
        />

        <DashboardCard
          cardState={dashboardCardSubscription}
          disableActionBtn={hasScheduledDowngrade}
        />

        <DashboardCard cardState={dashboardCardPaymentMethod} />

        <DashboardCard cardState={dashboardCardUsage} />
      </Box>
      {/*  */}
      <OverageSummary
        isUserPage={props.isUserPage}
        renderIfEnterprise={true}
        showAlerts={true}
        showActions={true}
      />
      {/*  */}
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: 400,
          padding: "16px",
          width: "100%",
          gap: "30px",
        }}
      >
        <CardHeader
          title="Credit Usage"
          style={{ padding: "0" }}
          variant="h5"
          subheader="Cumulative Daily credit usage over your current billing period"
        ></CardHeader>
        <CardContent sx={{ height: "100%", width: "100%", padding: "0" }}>
          {(dailyUsageQuery.isLoading || subscriptionQuery.isLoading) && (
            <>
              <Skeleton variant="rectangular" width="100%" height="400px" />
            </>
          )}
          {isSuccess && (
            <>
              <div ref={chartRef} style={{ height: 400, width: "100%" }} />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
