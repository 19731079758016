import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { blue } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useEffect, useState } from "react";
import { DrawComponent } from "./draw-component";
import { UploadComponent } from "./upload-component";
import { BoundaryComponent } from "./boundary-component";
import React from "react";
import { zoomToFeature } from "pages/geoscape-data/explorer/utils";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { BoundaryItem } from "./boundary-item";
import { area, feature } from "@turf/turf";
import {
  DrawCreateEvent,
  DrawModeChangeEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from "@mapbox/mapbox-gl-draw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { GeoJSONFeatureCollection } from "../../models";
import { Warning } from "@mui/icons-material";

export type FeatureOrFeatureArray =
  | Feature<Geometry, GeoJsonProperties>
  | Feature<Geometry, GeoJsonProperties>[];

export interface BoundaryToolProps {
  selectedFeature: string | null;
  setSelectedFeature: React.Dispatch<React.SetStateAction<string | null>>;
  addOrUpdateFeatures: (feat: FeatureOrFeatureArray) => void;
  currentMode: MapboxDraw.DrawMode | "draw_rectangle" | "draw_circle";
  setCurrentMode: React.Dispatch<
    React.SetStateAction<MapboxDraw.DrawMode | "draw_rectangle" | "draw_circle">
  >;
}

const boundarySteps = [
  {
    stepName: "Upload",
    element: UploadComponent,
  },
  {
    stepName: "Draw",
    element: DrawComponent,
  },
  {
    stepName: "Boundary",
    element: BoundaryComponent,
  },
];

function calculateByteSize(obj: any) {
  const jsonString = JSON.stringify(obj);
  return new Blob([jsonString]).size;
}

const calculateTotalArea = (
  features: GeoJSON.Feature<GeoJSON.Geometry>[]
): number => {
  const totalArea = features.reduce(
    (sum, feature: GeoJSON.Feature<GeoJSON.Geometry>) => {
      return sum + area(feature);
    },
    0
  );
  return totalArea;
};

const geometrySizeLimitKb = 450;

export const DefineBoundary = (
  props: ClipConfigProps & ClipStepsControlProps
) => {
  const classes = useStyles();
  const [component, setComponent] = useState("Draw");

  const [currentMode, setCurrentMode] = useState<
    MapboxDraw.DrawMode | "draw_rectangle" | "draw_circle"
  >(MapboxDraw.constants.modes.SIMPLE_SELECT);

  const [features, setFeatures] = useState<GeoJSON.Feature<GeoJSON.Geometry>[]>(
    props.clipConfig.geometry
      ? props.clipConfig.geometry.features.map((x) => x)
      : []
  );

  const [selectedFeature, setSelectedFeature] = useState<string | null>(null);

  const handleSelectSidebar = (id: string) => {
    props.draw.changeMode("simple_select", { featureIds: [id] });
    setSelectedFeature(id);
    const selectedFeature = features.find((x) => x.id === id);
    if (!selectedFeature) {
      return;
    }
    zoomToFeature(props.mapRef, selectedFeature.geometry);
  };

  const handleSelectMap = (e: DrawSelectionChangeEvent) => {
    const multiSelect = e.features.length > 1;
    const first = e.features.pop();
    if (!first) {
      setSelectedFeature(null);
      return;
    }
    setSelectedFeature(first.id as string);

    // Hack to stop the user multi-selecting
    if (multiSelect) {
      //@ts-ignore
      props.draw.changeMode("direct_select", {
        featureId: first.id,
      });
    }
  };

  const handleDelete = (id: string) => {
    props.draw.delete([id]);
    setFeatures((prev) => {
      const newFeatures = prev.filter((x) => x.id != id);
      updateConfigGeometry(newFeatures);
      return newFeatures;
    });
  };

  const handleRename = (id: string, name: string) => {
    setFeatures((prev) => {
      const newFeatures = prev.map((feature) => {
        if (feature.id === id) {
          if (feature.properties) {
            feature.properties.name = name;
          }
        }
        return feature;
      });
      updateConfigGeometry(newFeatures);
      return newFeatures;
    });
  };

  const _insertFeatures = (
    features: Feature<Geometry, GeoJsonProperties>[]
  ) => {
    setFeatures((prevFeatures) => {
      const existingFeatureIds = prevFeatures.map((x) => x.id as string);
      const newFeatures = features.filter(
        (x) => !existingFeatureIds.includes(x.id as string)
      );
      const updateFeatures = features.filter((x) =>
        existingFeatureIds.includes(x.id as string)
      );
      const updateFeatureIds = updateFeatures.map((x) => x.id as string);
      const updatedFeatures = prevFeatures.map((f) => {
        if (!updateFeatureIds.includes(f.id as string)) {
          return f;
        }
        const newAttributes = updateFeatures.find(
          (x) => (x.id as string) === (f.id as string)
        ) as Feature<Geometry, GeoJsonProperties>;
        f.geometry = newAttributes.geometry;
        const prevName = f.properties?.name;
        f.properties = { ...newAttributes.properties, name: prevName };
        return f;
      });
      let finalFeatures = [...updatedFeatures, ...newFeatures];
      finalFeatures = finalFeatures.map((x, i) => {
        if (!x.properties) {
          x.properties = {};
        }
        if (!x.properties.name) {
          x.properties.name = `Boundary ${i + 1}`;
        }
        return x;
      });
      updateConfigGeometry(finalFeatures);
      return finalFeatures;
    });
  };

  const addOrUpdateFeatures = (f: FeatureOrFeatureArray) => {
    if (Array.isArray(f)) {
      _insertFeatures(f);
    } else {
      _insertFeatures([f]);
    }
  };

  const updateConfigGeometry = (
    features: GeoJSON.Feature<GeoJSON.Geometry>[]
  ) => {
    props.setClipConfig((configFeatureCollection) => {
      configFeatureCollection.geometry = {
        type: "FeatureCollection",
        features: features,
      };
      return configFeatureCollection;
    });
  };

  const handleModeChange = (e: DrawModeChangeEvent) => {
    setCurrentMode(e.mode);
  };

  const onUpdate = (e: DrawUpdateEvent) => {
    addOrUpdateFeatures(e.features[0]);
  };

  const onCreate = (e: DrawCreateEvent) => {
    let feat = e.features[0];
    addOrUpdateFeatures(feat);
  };

  useEffect(() => {
    //@ts-ignore
    props.mapRef?.current.on("draw.create", onCreate);
    //@ts-ignore
    props.mapRef?.current.on("draw.update", onUpdate);
    //@ts-ignore
    props.mapRef?.current.on("draw.selectionchange", handleSelectMap);
    //@ts-ignore
    props.mapRef?.current.on("draw.modechange", handleModeChange);
  }, []);

  const isConfigTooBig =
    calculateByteSize(props.clipConfig) > geometrySizeLimitKb * 1024;

  const isTooManyFeatures = features.length > 20;

  const isAreaTooBig = calculateTotalArea(features) / 1000000 > 100;

  return (
    <Box display="flex" flexDirection="column" gap={1} height="100%">
      <Typography variant="h5">Define Clip Boundary</Typography>
      <Box display="flex" flexDirection="row" gap={1}>
        <Card
          className={
            component === "Upload" ? classes.typecardSelected : classes.typecard
          }
          variant="outlined"
        >
          <CardActionArea onClick={() => setComponent("Upload")}>
            <CardContent className={classes.typecard}>
              <Avatar sx={{ bgcolor: blue[50] }}>
                <UploadFileIcon color="info" />
              </Avatar>
              <Typography variant="subtitle1">Upload</Typography>
              <Typography variant="body2">ZIP(10MB)</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          className={
            component === "Draw" ? classes.typecardSelected : classes.typecard
          }
          variant="outlined"
        >
          <CardActionArea onClick={() => setComponent("Draw")}>
            <CardContent className={classes.typecard}>
              <Avatar sx={{ bgcolor: blue[50] }}>
                <EditSharpIcon color="info" />
              </Avatar>
              <Typography variant="subtitle1">Draw</Typography>
              <Typography variant="body2" color="text.secondary">
                Boundary
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className={classes.typecardDisabled} variant="outlined">
          <CardActionArea onClick={() => setComponent("Boundary")}>
            <CardContent className={classes.typecardDisabled}>
              <Avatar className={classes.disabled}>
                <SearchSharpIcon className={classes.disabled} />
              </Avatar>
              <Typography variant="subtitle1" className={classes.disabled}>
                Boundary
              </Typography>
              <Typography variant="body2" className={classes.disabled}>
                Search
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <Box>
        {React.createElement(
          boundarySteps.filter((b) => b.stepName === component)[0].element,
          {
            selectedFeature,
            setSelectedFeature,
            addOrUpdateFeatures,
            currentMode,
            setCurrentMode,
            ...props,
          }
        )}
      </Box>

      <Box display="flex" flexDirection="column" gap={1} overflow="auto">
        <Box display="flex" flexDirection="column" gap={1}>
          {features.map((feature, idx) => (
            <BoundaryItem
              key={feature.id as string}
              id={feature.id as string}
              selected={feature.id == selectedFeature}
              name={feature.properties?.name ?? `Boundaries ${idx}`}
              area={area(feature)}
              onDelete={handleDelete}
              onEdit={handleRename}
              onSelect={handleSelectSidebar}
            ></BoundaryItem>
          ))}
        </Box>
      </Box>
      {isConfigTooBig && (
        <Alert icon={<Warning />} color="warning">
          Geometry exceeds {geometrySizeLimitKb}kb, remove some features to
          continue
        </Alert>
      )}
      {isTooManyFeatures && (
        <Alert icon={<Warning />} color="warning">
          Number of features exceeds the maximum of 20, remove{" "}
          {features.length - 20} to continue
        </Alert>
      )}
      {isAreaTooBig && (
        <Alert icon={<Warning />} color="info" variant="outlined">
          For large boundaries a commercial license could be more suitable.
        </Alert>
      )}
      <Box alignSelf="flex-end" marginTop="auto" display="flex">
        <Button
          disabled={
            features.length === 0 || isConfigTooBig || isTooManyFeatures
          }
          onClick={() => {
            props.setClipConfig((prev) => {
              props.setActiveStep(
                Math.min(props.activeStep + 1, props.maxSteps)
              );
              return prev;
            });
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  typecard: {
    width: "126px",
    height: "136px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  typecardSelected: {
    width: "126px",
    height: "136px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.action.selected,
  },
  typecardDisabled: {
    width: "126px",
    height: "136px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
}));
