import { Box, ListItemButton, MenuList, Paper } from "@mui/material";

import { Link, ListItemText, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { SideBarSubMenuItemProps } from "./utils-side-bar";
import { useSnackbar, SnackbarKey } from "notistack";

import { SideBarSubMenuProps } from "./utils-side-bar";
import { SupportButton } from "components/snackbars/SupportButton";
import { CloseButton } from "components/snackbars/CloseButton";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

export default function SideBarSubMenu(props: SideBarSubMenuProps) {
  if (props) {
    return (
      <Paper sx={{ height: "100%", zIndex: 1 }}>
        <MenuList sx={{ width: 182 }}>
          {props.menuItems?.map((item, index) => (
            <SideBarSubMenuItem {...item} key={index} />
          ))}
        </MenuList>
      </Paper>
    );
  }
  return <></>;
}

const SideBarSubMenuItem = (props: SideBarSubMenuItemProps) => {
  const { name, link, notImplemented, target, disabled, items = [] } = props;
  const currentRoute = useLocation().pathname;
  const classes = useStyles();

  const { enqueueNotImplementedSnackbar } = useCustomSnackbars();

  return (
    <>
      {/* Render the main category of the sidebar sub menu item */}
      {/* If the main category has children display the heading as text, otherwise a clickable nav */}
      {items.length > 0 ? (
        <ListItemText primary={name} className={classes.menuItem} />
      ) : (
        <MenuItem
          onClick={
            notImplemented
              ? () => {
                  enqueueNotImplementedSnackbar();
                }
              : undefined
          }
          component={target ? ListItemButton : Link}
          disabled={disabled}
          target={target}
          // href={link}
          href={notImplemented ? undefined : link}
          key={link}
          selected={currentRoute === link}
          dense={false}
          className={classes.menuItem}
        >
          <ListItemText primary={name} />
        </MenuItem>
      )}

      {/* Render the children of the sidebar sub menu item */}
      {items.map((item, index) => (
        <MenuItem
          onClick={
            item.notImplemented
              ? () => {
                  enqueueNotImplementedSnackbar();
                }
              : undefined
          }
          component={item["target"] ? ListItemButton : Link}
          target={item["target"]}
          href={item.notImplemented ? undefined : item.link}
          key={item.link}
          selected={currentRoute === item.link}
          disabled={item["disabled"]}
          dense={true}
          className={classes.childMenuItem}
        >
          <ListItemText primary={item.name} />
        </MenuItem>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  childMenuItem: {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "40px",
    paddingRight: "32px",
  },
  menuItem: {
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "24px",
    paddingRight: "32px",
  },
});
