import * as QueryKeys from "data";

// reactQuery
import {
  fetchApiKeys,
  fetchSubscription,
  fetchUsage,
  fetchUsage_,
} from "data/queries";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";

import { IdentityState } from "contexts/identity-context";
import { DateRange, UsageState } from "contexts/models";

export const useFetchSubscription = (
  isUser: boolean,
  identityState: IdentityState
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(isUser, identityState)
  );
};

export const useFetchApiKeys = (
  isUser: boolean,
  identityState: IdentityState
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery({
    queryKey: [QueryKeys.apiKeys, identityId],
    queryFn: () => fetchApiKeys(isUser, identityState),
  });
};

export const useFetchUsageGraph = (
  isUser: boolean,
  identityState: IdentityState,
  startTime: number,
  endTime: number,
  duration: DateRange,
  setUsageGraph: React.Dispatch<React.SetStateAction<UsageState>>
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery({
    queryKey: [QueryKeys.usageGraph, identityId, startTime, endTime],
    queryFn: () => fetchUsage(isUser, identityState, startTime, endTime),
    onSuccess: (queryData) => {
      setUsageGraph((prevState) => {
        return {
          ...prevState,
          startTime: startTime,
          endTime: endTime,
          usage: queryData,
          duration: duration,
        };
      });
    },
  });
};

export const useFetchUsageGraph_ = (
  isUser: boolean,
  identityState: IdentityState,
  startTime: number,
  endTime: number,
  enabled?: boolean
) => {
  const identityId = resolveIdentityId(identityState, isUser);

  return useQuery({
    queryKey: [QueryKeys.usageGraph, identityId, startTime, endTime],
    queryFn: () => fetchUsage_(isUser, identityState, startTime, endTime),
    enabled: enabled,
  });
};
