import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";

interface CallToActionItem {
  heading: string;
  text: string;
  icon?: any;
}

export const CallToActionList = (props: {
  callToActions: CallToActionItem[];
}) => {
  return (
    <Box style={{ marginTop: "200px" }} bgcolor={"background.default"}>
      <Typography variant="h5">
        A self-serve portal empowering data exploration and API management
      </Typography>
      <List>
        {props.callToActions.map((callToActionItem) => (
          <ListItem>
            {callToActionItem.icon !== false ? (
              <ListItemAvatar>
                <Avatar>{callToActionItem.icon}</Avatar>
              </ListItemAvatar>
            ) : (
              ""
            )}
            <Box>
              <Typography variant="h6">{callToActionItem.heading}</Typography>
              <Typography>{callToActionItem.text}</Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
